<template>
    <div class="form-list form-list-two">
        <p class="h-handle-button h-handle-bg" v-if="buttonVisible" style="margin-left:0px;">
            <el-button type="primary" size="small" @click="add" :disabled="disabled">新增</el-button>
            <el-button type="text" size="small" @click="del" :disabled="disabled">删除</el-button>
        </p>
        <div class="h-table-list">
            <el-table :data="data" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="33" v-if="buttonVisible"></el-table-column>
                <el-table-column v-for="col in colDefs" :key="col.prop" :prop="col.prop" :min-width="60" :label="col.label">
                    <template slot-scope="scope">
                        <template v-if="col.type==='text'">
                            <el-input type="text" v-if="scope.row.RowEdit" :maxlength="col.maxlength||20" :minlength="1" v-model="scope.row[col.prop]"
                                style="width:100%;" />
                            <span v-if="!scope.row.RowEdit">{{scope.row[col.prop]}}</span>
                        </template>
                        <template v-else-if="col.type==='search'">
                            <el-input v-if="scope.row.RowEdit" :maxlength="col.maxlength||20" suffix-icon="el-icon-search" :minlength="1" v-model="scope.row[col.prop]"
                                style="width:100%;" readonly @click.native="col.onSearch && col.onSearch(scope.row)" />
                            <span v-if="!scope.row.RowEdit">{{scope.row[col.prop]}}</span>
                        </template>
                        <template v-else-if="col.type==='select'">
                            <el-select v-model="scope.row[col.prop]" placeholder="请选择" v-if="scope.row.RowEdit" @change="col.selectchange && col.selectchange(colDefs,scope.row)"
                                style="width:100%;" size="mini">
                                <el-option v-for="item in col.data" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                            <span v-if="!scope.row.RowEdit">{{selectForLabel(col,scope.row[col.prop])}}</span>
                        </template>
                        <template v-else-if="col.type==='date'">
                            <el-date-picker v-if="scope.row.RowEdit" v-model="scope.row[col.prop]" type="date" placeholder="选择日期" style="width:100%;"></el-date-picker>
                            <span v-if="!scope.row.RowEdit">{{scope.row[col.prop]}}</span>
                        </template>
                        <template v-else-if="col.type==='datetime'">
                            <el-date-picker v-if="scope.row.RowEdit" v-model="scope.row[col.prop]" type="datetime" placeholder="选择日期时间" style="width:100%;"></el-date-picker>
                            <span v-if="!scope.row.RowEdit">{{scope.row[col.prop]}}</span>
                        </template>
                        <template v-else-if="col.type==='number'">
                            <el-input-number size="small" v-if="scope.row.RowEdit" :min="1" :max="99" :controls=false v-model="scope.row[col.prop]" style="width:100%;"></el-input-number>
                            <span v-if="!scope.row.RowEdit">{{scope.row[col.prop]}}</span>
                        </template>
                        <div v-else>{{ scope.row[col.prop] }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="!scope.row.RowEdit" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="text" size="small" v-show="scope.row.RowEdit" @click="ok(scope.row)">保存</el-button>
                        <el-button type="text" size="small" v-show="scope.row.RowEdit" @click="cancel(scope.row)">取消</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
    export default {
        name: "InlineTable",
        data() {
            return {
                multipleSelection: [],
                emptyDatas: [],
            }
        },
        props: {
            data: {},
            colDefs: {},
            buttonVisible: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            cancelPredicate:
            {
                type: Function,
                default: (row) => false
            },
            okPredicate:
            {
                type: Function,
                default: (row) => true
            },
        },
        methods: {
            handleSelectionChange: function (val) {
                this.multipleSelection = val;
            },
            selectForLabel(col, val) {
                if (col.data) {
                    for (var i = 0; i < col.data.length; i++) {
                        var item = col.data[i];
                        if (item.value == val)
                            return item.label;
                    }
                    return '';
                } else
                    return '';
            },
            add() {
                var newData = { RowEdit: true };
                for (var i in this.colDefs) {
                    switch (this.colDefs[i].type) {
                        case 'number':
                            this.$set(newData, this.colDefs[i].prop, 1);
                            break;
                        case 'select':
                            this.$set(newData, this.colDefs[i].prop, this.colDefs[i].data[0].value);
                            break;
                        default:
                            this.$set(newData, this.colDefs[i].prop, '');
                    }
                }
                this.$set(newData, 'Tag', this.Utils.cloneObj(newData));
                this.$set(newData, 'IsFirstEdit', true);
                this.data.push(newData);
                this.changeFocus(this.$el);
            },
            del() {
                if (this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择删除项.`);
                    return false;
                }

                this.multipleSelection.forEach(m => this.data.remove(m));
            },
            edit: function (row) {
                this.$set(row, 'RowEdit', true);
                this.$set(row, 'IsFirstEdit', false);
                this.$set(row, 'Tag', this.Utils.cloneObj(row));
                this.changeFocus(this.$el);
            },
            ok: function(row) {
                var isOk = this.okPredicate && this.okPredicate(row);
                if(isOk)
                    row.RowEdit = false;
            },
            cancel: function (row) {
                var isRemove = this.cancelPredicate && this.cancelPredicate(row);
                if (isRemove && !!row.IsFirstEdit) {
                    this.data.remove(row);
                } else {
                    for (var i in this.colDefs) this.$set(row, this.colDefs[i].prop, row.Tag[this.colDefs[i].prop]);
                    row.RowEdit = false;
                }
            },
            changeFocus(obj) {
                if (this.colDefs.length > 0 &&
                    this.colDefs[0].type != 'select' &&
                    this.colDefs[0].type != 'date' &&
                    this.colDefs[0].type != 'datetime'
                ) {
                    this.$nextTick(() => {
                        $("input[type=text]:first", obj).focus();
                    });
                }
            },
            checkEmptyLine() {
                this.data.forEach(item => {
                    var count = 0;
                    for (var i in this.colDefs) {
                        if (this.colDefs[i].prop != void 0 && item[this.colDefs[i].prop] == (this.colDefs[i].type === 'number' ? 0 : ''))
                            count++;
                    }
                    if (count == this.colDefs.length)
                        this.emptyDatas.push(item);
                });
            },
            clear() {
                this.checkEmptyLine();
                this.clearEmptyLine();
            },
            clearEmptyLine() {
                this.emptyDatas.forEach(item => this.data.remove(item));
                this.emptyDatas.length = 0;
            }
        }
    }
</script>
<style scoped>
</style>