<template>
    <div class="sort-box" v-show="isShow" v-bind:style="[styles]" v-click-outside="onClickOutside">
        <ul>
            <li v-if="FieldSortable">
                <span>排序：</span>
                <div class="sort-right">
                    <a @click="sortFilter('1')">升序</a><a @click="sortFilter('0')">降序</a>
                </div>
            </li>
            <li v-if="FieldFilterable">
                <span class="sort-font"><em>{{ FieldLabel }}</em>：</span>
                <div class="sort-right  form-list">
                    <el-date-picker v-model="FieldValueStart" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间">
                    </el-date-picker>
                    至
                    <el-date-picker v-model="FieldValueEnd" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间">
                    </el-date-picker>
                </div>
            </li>
            <li v-if="FieldFilterable">
                <div class="sort-btn">
                    <el-button @click="swithFilter">返回</el-button>
                    <el-button type="primary" @click="confirmFilter">确认</el-button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

import Event from '../../../../scripts/event.js'

export default {
    name: 'datetimefield-filter',
    data: function () {
        return {
            isShow: false,
            isToggle: false,
            context: {},
            styles: {},
            fieldDef: Object,
            FeldLabel: "",
            FieldValueStart: '',
            FieldValueEnd: '',
            FieldType: "6",
            FieldName: '',
            FieldSortable: false,
            FieldFilterable: false
        }
    },

    methods: {
        sortFilter: function (order) {
            this.swithFilter();
            this.context.PageIndex = 1;
            // (!this.context.OrderBy) && (this.context.OrderBy = {});
           // this.context.OrderBy = {};
            // this.context.OrderBy[this.FieldName] = order;

            this.context.OrderBy = [];
            this.context.OrderBy.push({
                FieldName: this.FieldName,
                OrderByType: order
            });


            this.parent.$emit('onPageChange', this.context);
        },
        confirmFilter: function () {
            this.swithFilter();
            this.context.PageIndex = 1;
            if (!this.context.Params) {
                this.context.Params = {};
            }
            let obj = {};
            obj.FieldName = this.FieldName;
            obj.FieldValueStart = this.FieldValueStart;
            obj.FieldValueEnd = this.FieldValueEnd;
            obj.FieldType = this.FieldType;
            obj.FieldLabel = this.FieldLabel;
            // this.context.Params[this.FieldName] = obj;
            this.$set(this.context.Params, this.FieldName, obj)

            this.parent.$emit('onPageChange', this.context);

        },
        swithFilter: function () {
            this.isShow = !this.isShow;
            $('.el-table__fixed-header-wrapper').css('z-index', '6');
        },
        onClickOutside(event) {
            this.isShow = false;
            if (this.isToggle) {
                this.isShow = true;
                this.isToggle = false;
            }
        }

    },
    mounted: function () {
        var _this = this;
        Event.$on("datetimefilter", function ({ e, column, context, colDef, parent }) {

            _this.parent = parent;
            _this.fieldDef = colDef;
            _this.FieldLabel = colDef.DisplayName;
            // _this.FieldName = colDef.QueryField;
            _this.FieldName = colDef.FieldName; // 和后端保持一致
            _this.FieldSortable = colDef.Sortable;
            _this.FieldFilterable = colDef.Filterable;
            _this.context = context;
            _this.FieldValueStart = "";
            _this.FieldValueEnd = "";
            if (_this.context.Params && _this.context.Params[_this.FieldName]) {

                _this.FieldValueStart = _this.context.Params[_this.FieldName].FieldValueStart;
                _this.FieldValueEnd = _this.context.Params[_this.FieldName].FieldValueEnd;
            }

            _this.isToggle = true;
            _this.swithFilter();
            if ((window.innerWidth - e.clientX) < 250) {
                _this.styles = { top: +e.clientY + 10 + 'px', right: '5px', left: 'auto' }
            } else {
                _this.styles = { top: +e.clientY + 10 + 'px', left: +e.clientX - 10 + 'px' }
            }

        });
    }

}

</script>

