import Event from "./event.js";
import Store from "./store.js";
import config from "@/scripts/config.js";
import sha from './sha.js';
import Cookie from './cookie.js'

export default {
    install: function (Vue, name) {
        Object.defineProperty(Vue.prototype, name || '$AjaxRequest', { value: AjaxRequest });
        Object.defineProperty(Vue.prototype, "$ajax", { value: $Ajax });
    }
}

var AjaxRequest = {};

AjaxRequest.AjaxSubmit = function (requestUrl, requestType, dataPara, successCallback, failedCallback, isAsync,isCache) {
    //requestUrl = config.subdomain + "/operations/" + requestUrl;
    if(!(requestUrl.indexOf("https://")>-1) && !(requestUrl.indexOf("http://")>-1)){
        var domain = Cookie.get(Cookie.domain);
        if(domain){
            requestUrl =window.AppConfig.apiCustomerUrl + requestUrl;
        }else{
            requestUrl =window.AppConfig.apiBaseUrl+ requestUrl;
        }
    }
    AjaxRequest.openLoading();

    if (dataPara && typeof dataPara == 'object' && requestType.toLowerCase() == "post") {
        dataPara = JSON.stringify(dataPara);
    }
    var async = true;
    if (isAsync != null && isAsync != void 0) async = isAsync;
    var cacheFlag = false;
    if (isCache != null && isCache != void 0) cacheFlag = isCache;

    if (requestUrl.indexOf("?") > -1) {
        var f = requestUrl.slice(0, requestUrl.indexOf("?") + 1);
        var s = requestUrl.slice(requestUrl.indexOf("?") + 1);
        if (s.indexOf("currentType") < 0) {
            f += "currentType=" + Store.state.global.currentType;
        }
        if (s.indexOf("currentCCode") < 0) {
            f += "&currentCCode=" + Store.state.global.currentCCode;
        }
        requestUrl = f + "&" + s;
    } else {
        requestUrl = requestUrl + "?currentType=" + Store.state.global.currentType + "&currentCCode=" + Store.state.global.currentCCode;
    }
    $.ajax({
        url: requestUrl,
        type: requestType,
        data: dataPara,
        async: async,
        cache: cacheFlag,
        contentType: 'application/json; charset=utf-8',
        xhrFields: {
            'Access-Control-Allow-Origin': '*',
        },
        success: function (result) {
            AjaxRequest.closeLoading();
            if (successCallback) {
                successCallback(result);
            }
        },
        complete: function (XMLHttpRequest, textStatus) {

        },
        beforeSend: function (jqxhr, settings) {
            //jqxhr.setRequestHeader("Authorization", "Bearer " + window.localStorage.getItem('headertoken'));

        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            AjaxRequest.closeLoading();
            //  console.error && console.error(`异常请求=>url:${this.url}|type:${this.type}|desc:${XMLHttpRequest.responseJSON.OperationDesc || ''}`);
            if (failedCallback && typeof failedCallback == 'function')
                failedCallback(errorThrown);
            else {
                console.log(errorThrown);
                if (XMLHttpRequest && XMLHttpRequest.responseJSON && XMLHttpRequest.responseJSON.OperationDesc) {
                    Event.$message({
                        showClose: true,
                        message: XMLHttpRequest.responseJSON.OperationDesc,
                        type: 'error'
                    });
                }
                else if (XMLHttpRequest) {
                    Event.$message({
                        showClose: true,
                        message: XMLHttpRequest.toString(),
                        type: 'error'
                    });
                }
                else {
                    Event.$message({
                        showClose: true,
                        message: "响应出错",
                        type: 'error'
                    });
                }
                // alert(XMLHttpRequest.responseJSON.OperationDesc);
                //alert("系统繁忙,请稍后再试!");
            }
        },
        headers: {          
            "X-Authorization":"Bearer "+ sha.getToken1(),
            "sign": sha.getParamSign(requestUrl, dataPara)
        }
    });
}
AjaxRequest.FormSubmit = function (formElement, requestUrl, requestType, callBack) {
    var dataPara = {};
    var a = formElement.serializeArray();
    $.each(a, function () {
        if (dataPara[this.name] !== undefined) {
            if (!dataPara[this.name].push) {
                dataPara[this.name] = [dataPara[this.name]];
            }
            dataPara[this.name].push(this.value || '');
        } else {
            dataPara[this.name] = this.value || '';
        }
    });
    AjaxRequest.AjaxSubmit(requestUrl, requestType, dataPara, callBack);
}
AjaxRequest.openLoading = function () {
    var el = document.getElementById('loading');
    if (el == undefined || el == null) {
        var el = document.createElement('div');
        el.id = "loading";
        var elChild = document.createElement('div');
        elChild.className = "loading";
        document.body.appendChild(el)
        document.getElementById('loading').appendChild(elChild);
    }
}

AjaxRequest.closeLoading = function () {
    var el = document.getElementById('loading');
    if (el != null) {
        document.body.removeChild(el)
    }
}

var $Ajax = {
    /*
       Network data request
    */
    send: function (url, method, data, sucessCalback, failedCallback, isAsync,isCache) {
        var async = false;
        if (isAsync != null && isAsync != void 0) async = isAsync;
        var cacheFlag = false;
        if (isCache != null && isCache != void 0) cacheFlag = isCache;

        //console.log(`url:${url};method:${method}`);
        AjaxRequest.AjaxSubmit(url, method, data, data => {
            try {
                if (data.IsSuccess) {
                    sucessCalback && sucessCalback(data);
                } else {
                    if (failedCallback)
                        failedCallback(data.OperationDesc);
                    else {
                        console.error && console.error(`异常请求=>url:${url}|type:${method}`);
                        this.messageBox(data.OperationDesc);
                    }
                }
            } catch (ex) {
                console.error && console.error(`异常请求=>url:${url}|type:${method}|exception:${ex.message}`);
                this.messageBox(ex.message);
            }
        }, failedCallback, async ,cacheFlag);
    },
    query: function (url, method, data, sucessCalback, failedCallback) {
        //拷贝参数
        var dataCopy = JSON.parse(JSON.stringify(data));
        if (data.Params != undefined) {
            var params = {};
            var propertyArr = Object.getOwnPropertyNames(data.Params);
            //列表查询参数解析
            for (let i = 0; i < propertyArr.length; i++) {
                var key = propertyArr[i];
                var fieldType = data.Params[key] && data.Params[key].FieldType;
                if (!fieldType) {
                    if (typeof data.Params[key] == "object") continue;//系统属性
                    //页面自定义的查询条件(非fieldlist列表控件上的条件),构造成后端接口能够接收的模型
                    params[key] = { FieldLabel: '', FieldValue: data.Params[key], FieldName: key, FieldType: 0, FieldValueStart: "", FieldValueEnd: "" };// ;//页面自定义参数
                } else {
                    params[key] = data.Params[key];
                }
            }
            dataCopy.Params = params;
        }
        AjaxRequest.AjaxSubmit(url, method, dataCopy, data => {
            if (data.IsSuccess) {
                sucessCalback && sucessCalback(data);
            } else {
                if (failedCallback)
                    failedCallback(data.OperationDesc);
                else
                    this.messageBox(data.OperationDesc);
            }
        }, null, true);
    },
    /*
        type:success|warning|danger|info
    */
    messageBox: function (msg, type, title) {
        type = !type ? 'error' : type;
        if (type == 'error') {
            const h = Event.$createElement;
            Event.$msgbox({
                duration: 1000 * 30,
                //showClose: true,
                message: h('p', {
                    style: {
                        maxWidth: '330px',
                        minWidth: '250px',
                        maxHeight: '250px',
                        overflow: 'auto'
                    },
                    domProps: {
                        innerHTML: msg
                    },
                }, null),
                type: type,
                title: title || { 'success': '成功', 'warning': '警告', 'danger': '危险', 'info': '信息', 'error': '错误' }[type]
            });
        } else {
            Event.$message({
                showClose: true,
                message: msg,
                type: type || 'error'
            });
        }
    },
};