<template>
    <div style="max-height:400px;overflow:auto">
        <el-tabs type="border-card">
            <el-tab-pane label="省市">
                <el-tree :data="dataSource"
                         :props="props"
                         show-checkbox
                         node-key="id"
                         ref="areaTree"
                         :default-expanded-keys="expandedKeys"
                         :default-checked-keys="checkedKeys"
                         highlight-current>
                </el-tree>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
    export default {
        name: "DistTree",
        data() {
            return {
                data: [],
                props: {
                    label: 'label',
                    children: 'children',
                    isLeaf: 'isLeaf'
                },
                expandedKeys: [],
                checkedKeys: [],
                dataSource: []
            }
        },
        props: {
        },
        mounted() {
            this.geAreaList();
        },
        methods: {
            clickNode: function (obj, checked, tree) {
                if (checked) {
                    this.expandedKeys.push(obj.id);
                }
            },
            geAreaList() {
                var _this = this;
                var model = [];
                model.push({ code: '0', label: '全选' });
                var arealist = localStorage.getItem('50omsarealist');
                var area = {};
                if (!arealist) {
                    this.$ajax.send("omsapi/tcfuserpersonal/getaddressselectdata", "get", null, data => {
                        area = data.Result;
                        localStorage.setItem('50omsarealist', JSON.stringify(area));
                    }, null, true);
                } else {
                     area = JSON.parse(arealist);
                }

                area.ProvinceList.forEach(provice => {
                    provice.parentcode = '0';
                });
                model = model.concat(area.ProvinceList);
                model = model.concat(area.CityList);
                model = model.concat(area.CountyList);
                var result = [];
                var find = function (code, parent, isRoot) {
                    var childModel = model.filter(item => item.parentcode == code);
                    if (childModel && childModel.length > 0) {
                        childModel.forEach(menu => {
                            var hadChild = model.findIndex((item, index) => {
                                return item.parentcode == menu.code;
                            })
                            if (hadChild > -1) {
                                var childMenu = { id: menu.code, label: menu.label, children: [] };

                                if (parent != null)
                                    parent.children.push(childMenu);
                                else
                                    result.push(childMenu);

                                find(menu.code, childMenu, false);
                            }
                            else {
                                if (parent == null && isRoot)
                                    result.push({ id: menu.code, label: menu.label });
                                else if (parent != null)
                                    parent.children.push({ id: menu.code, label: menu.label });
                            }
                        });
                    }
                    else {
                        if (parent != null) result.push(parent);
                    }
                };
                find(null, null, true);
                _this.dataSource = result;
                this.expandedKeys.push('0');
            },
            loadNode: function (node, resolve) {
                if (node.level === 0) {
                    return resolve(this.dataSource);
                }
                if (node.level > 0) {
                    var children = node.data.children;
                    if (children != null && children.length > 0) {
                        for (var i = 0; i < children.length; i++) {
                            if (!children[i].children) {
                                children[i].isLeaf = true;
                            }
                        }
                        return resolve(children);
                    } else {
                        return resolve([]);
                    }
                }
            },
        }
    }
</script>
<style>
</style>