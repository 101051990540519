import FieldFilter from './src/fieldfilter.vue';
import TextFieldFilter from './src/fieldfiltertext.vue';
import DateFieldFilter from './src/fieldfilterdate.vue';
import EnumFieldFilter from './src/fieldfilterenumlist.vue';
import NumberFieldFilter from './src/fieldfilternumber.vue';
import DateTimeFieldFilter from './src/fieldfilterdatetime.vue';
FieldFilter.install = function (Vue) {
   Vue.mixin({
       props: {
           //tableID: String
          //colDefList: {
          //          type: Array,
          //          default: function () {
          //              return [];
          //          }
          //   },
          //filterResultList: {
          //    type: Array,
          //    default: function () {
          //        return [];
          //    }
          //},
        },
         methods:{  
             bindFilter: FieldFilter.bindFilter
    },
});

   Vue.component(FieldFilter.name, FieldFilter);
   Vue.component(TextFieldFilter.name, TextFieldFilter);
   Vue.component(DateFieldFilter.name, DateFieldFilter);
   Vue.component(DateTimeFieldFilter.name, DateTimeFieldFilter);
   Vue.component(EnumFieldFilter.name, EnumFieldFilter);
 Vue.component(NumberFieldFilter.name, NumberFieldFilter);
                                                       
};

export default FieldFilter;