<template>
    <div>
        <el-col :span="avgSpans" class="form-title">
            <el-select v-model="placeholders.ProvinceName" :maxlength="200" filterable placeholder="省" v-on:change="onProvinceChange">
                <el-option v-for="item in provinces"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"
                           :disabled="disabled">
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="avgSpans" class="form-title">
            <el-select v-model="placeholders.CityName" :maxlength="50" filterable placeholder="市" v-on:change="onCityChange" @visible-change="getCityList(placeholders.ProvinceID)">
                <el-option v-for="item in citys"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"
                           :disabled="disabled">
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="avgSpans" class="form-title">
            <el-select v-model="placeholders.CountyName" :maxlength="50" filterable placeholder="区(县)" v-on:change="onCountyChange" @visible-change="getCountyList(placeholders.CityID)">
                <el-option v-for="item in countys"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"
                           :disabled="disabled">
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="avgSpans" class="form-title" v-if="showTown">
            <el-input v-model.trim="placeholders.Town" placeholder="镇(街道)">
            </el-input>
        </el-col>
    </div>
</template>
<script>
    export default {
        name: "Distpicker",
        data() {
            return {
                provinces: [],
                totalcitys: [],
                totalcounty: [],
                citys: [],
                countys: [],
            }
        },
        props: {
            placeholders: {
                type: Object,
                default: {
                    NationCode: '',
                    ProvinceName: '',
                    ProvinceID: '',
                    CityName: '',
                    CityID: '',
                    CountyName: '',
                    CountyID: '',
                }
            },
            showTown: false,
            disabled: false,
        },
        computed:{
            avgSpans(){
                return this.showTown?6:8
            }
        },
        mounted() {
            this.geAreaList();
        },
        methods: {
            onProvinceChange(val) {
                if (this.disabled) return;
                this.changeDataSource(1, val);
            },
            onCityChange(val) {
                if (this.disabled) return;
                this.changeDataSource(2, val);
            },
            onCountyChange(val) {
                if (this.disabled) return;
                this.changeDataSource(3, val);
            },
            getCityList(pCode) {
                this.citys = this.totalcitys.filter(function (item) { return item.parentcode == pCode });
            },
            getCountyList(cCode) {
                this.countys = this.totalcounty.filter(function (item) { return item.parentcode == cCode });;
            },
            getItemByName(arr, name) {
                var result = { name: '', code: '' };
                if (!arr || arr.length <= 0 || !name) return result;

                for (let i = 0; i < arr.length; i++) {
                    var row = arr[i];
                    if (row.value == name || row.value.indexOf(name) != -1) {
                        result.name = row.value;
                        result.code = row.code;
                        break;
                    }
                }
                return result;
            },
            geAreaList() {
                var _this = this;
                var arealist = localStorage.getItem('50omsarealist');
                if (!arealist) {
                    this.$ajax.send("omsapi/district/getaddressselectdata", "get", null, data => {
                        var area = data.Result;
                        _this.provinces = area.ProvinceList;
                        _this.totalcitys = area.CityList;
                        _this.totalcounty = area.CountyList;

                        localStorage.setItem('50omsarealist', JSON.stringify(area));
                    }, null, true);
                } else {
                    var area = JSON.parse(arealist);
                    _this.provinces = area.ProvinceList;
                    _this.totalcitys = area.CityList;
                    _this.totalcounty = area.CountyList;
                }
            },
            changeDataSource(type, value) {
                switch (type) {
                    case 1:
                        var pcode = this.getItemByName(this.provinces, value);
                        if (pcode != null && pcode != undefined) {
                            this.getCityList(pcode.code);
                            this.placeholders.ProvinceName = pcode.name;
                            this.placeholders.ProvinceID = pcode.code;
                        }

                        var cityItem = this.getItemByName(this.citys, this.placeholders.CityName);
                        if (cityItem != null && cityItem != undefined) {
                            this.placeholders.CityName = cityItem.name;
                            this.placeholders.CityID = cityItem.code;
                            this.getCountyList(cityItem.code);
                        }

                        var countryItem = this.getItemByName(this.countys, this.placeholders.CountyName);
                        if (countryItem != null && countryItem != undefined) {
                            this.placeholders.CountyName = countryItem.name;
                            this.placeholders.CountyID = countryItem.code;
                        }

                        break;
                    case 2:
                        var city = this.getItemByName(this.citys, value);
                        if (city != null && city != undefined) {
                            this.getCountyList(city.code);
                            this.placeholders.CityName = city.name;
                            this.placeholders.CityID = city.code;
                            this.getCountyList(city.code);
                        }

                        var countryItem = this.getItemByName(this.countys, this.placeholders.CountyName);
                        if (countryItem != null && countryItem != undefined) {
                            this.placeholders.CountyName = countryItem.name;
                            this.placeholders.CountyID = countryItem.code;
                        }
                        break;
                    case 3:
                        var county = this.getItemByName(this.countys, value);
                        if (county != null && county != undefined) {
                            this.placeholders.CountyID = county.code;
                            this.placeholders.CountyName = county.name;
                        }
                        break;
                }
            }
        }
    }
</script>
<style>
</style>