let md5 = require('md5');

export default class sha
{
    /*43761-0x1299*/
    constructor() {

    }
    
    static encode(content) {
        if (!content) return '';
        var output = new Array();
        var i, l = content.length;
        for (i = 0; i < l; i++) output += (+content.charCodeAt(i) ^ 0x1299).toString(16);
        return output.toUpperCase();
    }

    static decode(content) {
        if (!content) return '';
        var reg = /(\w{4})/g;
        var arr = content.match(reg);
        var str = [];
        arr.forEach(item => str.push(String.fromCharCode(parseInt(item, 16) ^ 0x1299)));
        return str.join('');
    }

    static uuid() {
        var d = new Date().getTime();
        if (window.performance && typeof window.performance.now === "function") {
            d += performance.now(); //use high-precision timer if available
        }
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
    
    static getToken() {
        var token = localStorage.wmstoken + ":" + sha.uuid() + ":" + new Date().getTime();
        return sha.encode(token);
    }

    static getToken1() {
        var token = localStorage.wmstoken;
        return token;
    }

    static getRealToken() {
        var token = "Bearer "+localStorage.wmstoken;
        return token;
    }

    static getParamSign(requestUrl, params) {
        var urlParams = "";
        if (requestUrl.indexOf("?") > -1) {
            urlParams = requestUrl.substring(requestUrl.indexOf("?"));
        }
        var paramStr = "";
        if (typeof params == "string") {
            paramStr = params;
        }
        if (paramStr) {
            var sign = md5(urlParams + paramStr + localStorage.wmstoken);
            return sign;
        }
        return "";
    }
}