
import sha from './sha.js';

function sm2Encrypt1(str) {
 
    return sha.encode(str);
}

function sm2Decrypt1(str) {
    return sha.decode(str);
}

function initSm2PubConfig(pubkey) {
    localStorage.wmspubkey=pubkey;
}

function initSm2PriConfig(prikey) {
    localStorage.wmsprikey=prikey;
}

function clearCache()
{
    localStorage.wmsprikey="";
    localStorage.wmspubkey="";
    localStorage.wmstoken="";
    localStorage.wmsstatus=false;
}
//加密对象
var crypto={};
crypto.sm2Encrypt= sm2Encrypt1;
crypto.sm2Decrypt=sm2Decrypt1;
crypto.initSm2PubConfig=initSm2PubConfig;
crypto.initSm2PriConfig=initSm2PriConfig;
crypto.clearCache=clearCache;

export default
{
    install: function (Vue, name) {
        Object.defineProperty(Vue.prototype, "$crypto", { value: crypto });
    }
}




