import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

 const loginModule = {
    state: {
        login_name:''
    },
    mutations: {
        setLoginName: (state,name) => state.login_name = name ,
        clear: state => state.login_name = ''
    }
};

 const globalModule = {
     state: {
         pageRightCodes: [],
         hasRight: false,
         currentType: 1,
         currentCCode: "",
     },
     mutations: {
         hasRightCode: (state, code) => {
             if (!state.pageRightCodes || state.pageRightCodes.length == 0)
                 state.hasRight = false;
             else {
                 state.hasRight = false;
                 state.pageRightCodes.forEach(c => c == code && (state.hasRight = true));
             }
         },
         setRightCodes: (state, codes) => {
             state.pageRightCodes = codes;
         },
         clear: (state) => {
             state.pageRightCodes = [];
         }
     }
 };

export default new Vuex.Store({
    modules: {
        login: loginModule,
        global: globalModule
    }
});
