<template>
    <div class="h-tale-list">
        <field-selector :colDefs="colDefs"></field-selector>
        <el-table :data="dataList" style="width: 100%" border height="200px">
            <el-table-column v-for="colDef in colDefs"
                             :key="colDef.FieldName"
                             :prop="colDef.prop" :label="colDef.label" :fixed="colDef.fixed" :width="colDef.width"  >
            </el-table-column>
        </el-table>
        <el-pagination :current-page="currentPage"
                       :page-sizes="[10, 30, 50, 100]"
                       :page-size="PageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="totalCount">
        </el-pagination>
    </div>
</template>
<script>
    export default {
        name: 'dynamic-list',
          props: {
                dataList: {
                    type: Array,
                    default: function () {
                        return [];
                    }
                },
                colDefs: {
                    type: Array,
                    default: function () {
                        return [];
                    }
                },
                currentPage: {
                    type: Number,
                    default: function () {
                        return 1;
                    }
                },
                PageSize: {
                    type: Number,
                    default: function () {
                        return 10;
                    }
                },
                totalCount: {
                    type: Number,
                    default: function () {
                        return 0;
                    }
                },
        }
    }

</script>
<style>
.h-tale-list{margin:15px 12px 0 15px;}
.h-tale-list .el-table__body-wrapper{box-sizing:border-box;font-family:'Microsoft YaHei',微软雅黑;color:#333;}
.h-tale-list *{font-family:'Microsoft YaHei',微软雅黑;color:#333;}
.h-tale-list table{border-collapse:inherit;font-size:13px;text-align:center;}
.h-tale-list .el-table th,
.h-tale-list .el-table__footer-wrapper thead div,
.h-tale-list .el-table__header-wrapper thead div{background-color:#e6e7eb;text-align:center;}
.h-tale-list .el-table__footer-wrapper thead div,
.h-tale-list .el-table__fixed-header-wrapper thead div,
.h-tale-list .el-table__header-wrapper thead div{background-color:#e6e7eb;text-align:center;height:20px;line-height:20px;margin:3px;}
.h-tale-list .el-table--border td,
.h-tale-list .el-table--border th{border-color:#ddd;height:auto;}
.h-tale-list .el-table .cell{line-height:20px;margin:3px;}
.h-tale-list .el-table--enable-row-hover .el-table__body tr:hover>td,
.h-tale-list .el-table__body tr.hover-row.current-row > td,
.h-tale-list .el-table__body tr.hover-row.el-table__row--striped.current-row > td, 
.h-tale-list .el-table__body tr.hover-row.el-table__row--striped > td,
.h-tale-list .el-table__body tr.hover-row > td{background-color:#f3f4f6;}
.h-tale-list .el-pagination{margin-top:20px;}
.h-tale-list .el-button{border:1px solid #0063aa;color:#0063aa;padding:3px 5px;margin:1px 5px;}
.h-tale-list .el-button span{color:#0063aa;}
</style>