<template>
    <div class="h-handle">
        <div class="list-option selector-option" v-click-outside="onClickOutside">
            <p class="refrush" @click="onRefrush" title="刷新列表">
                <i class="el-icon-refresh" />
            </p>
            <p class="search" @click="onToggleSearch" title="展开/收起查询区域">
                <i :class="showQueryCondation ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
            </p>
            <p class="lo-icon" @click="showTableCol">
                <span><i></i></span><s></s>
            </p>
            <div class="lo-list" v-show="tabCol">
                <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <span class="col-handle">
                    <el-button type="text" style="padding-top: 10px;" plain @click="onResetSearch"
                        icon="el-icon-refresh-right" title="重置列表"> </el-button>
                </span>
                <el-checkbox-group v-model="checkedItems" @change="handleCheckedChange">
                    <div class="col-list" v-for="(colDef, index) in colDefs.BodyFieldParams" :key="index"
                        v-dragging="{ item: colDef, list: colDefs.BodyFieldParams, group: 'col' }" @dragend="dragend"
                        v-if="colDef.Configurable">
                        <!-- <div> -->
                        <el-checkbox :label="colDef.DisplayName" :key="colDef.FieldName" v-model="colDef.Visible"
                            title="显示/隐藏列，拖动可排序">{{ colDef.DisplayName }}</el-checkbox>
                        <div class="col-handle" title="添加/移除查询条件">
                            <!--<span class="up-down"><s class="up" @click="sortUp(colDef)" title="列向前移"></s><s class="down" @click="sortDown(colDef)" title="列向后移"></s></span>-->
                            <!--<s class="add-search" @click="addSearch(colDef)" title="添加|取消快速查询"></s>-->
                            <!-- <el-button type="primary" plain @click="addSearch(colDef)" icon="el-icon-plus"></el-button> -->
                            <el-button type="primary" plain @click="addSearch(colDef)"
                                :icon="colDef.Queryable ? 'el-icon-minus' : 'el-icon-plus'"></el-button>
                            <!-- <s class="fixed al" @click="fixedCol(colDef)" title="固定|取消列（固定后列自动移动到第一列）"></s>-->
                            <!--<s class="fixed al" @click="addWidth(colDef)" title="列宽度加大"></s>
                            <s class="fixed al" @click="subWidth(colDef)" title="列宽度减小"></s>-->
                        </div>
                        <!--</div>-->
                    </div>
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'field-selector',
    props: {
        dataSource: {
            type: Object,
        },
        colDefs: {
            type: Object,
            default: function () {
                return {
                    BodyFieldParams: []
                };
            }
        },

    },
    data() {
        return {
            tabCol: false,
            checkAll: false,
            checkedItems: [],
            showQueryCondation: true
        };
    },

    computed: {
        colProp: () => {
            var arr = []
            for (var i in this.colDefs.BodyFieldParams) {
                arr.push(this.colDefs.BodyFieldParams[i].FieldName);
            }
            return arr;
        }
    },
    watch: {
        colDefs: function () {
            this.checkAll = true;
            this.checkedItems.splice(0, this.checkedItems.length);
            for (var i in this.colDefs.BodyFieldParams) {
                if (this.colDefs.BodyFieldParams[i].Configurable) {
                    if (this.colDefs.BodyFieldParams[i].Visible) {
                        this.checkedItems.push(this.colDefs.BodyFieldParams[i].DisplayName);
                    }
                    else { this.checkAll = false; }
                }
            }
        },
    },
    methods: {
        dragend() {
            var _this = this;
            if (!_this.colDefs.hasOwnProperty('QueryName')) {
                return;
            }
            var bodyFieldParams = _this.colDefs.BodyFieldParams;
            if (_this.colDefs.hasOwnProperty('BodyFieldParams')) {
                for (let i = 0; i < bodyFieldParams.length; i++) {
                    bodyFieldParams[i].SortNo = i;
                }
            }

            _this.$ajax.send("omsapi/userQueryCfg/save", "post", _this.colDefs, (data) => {
                if (_this.colDefs.hasOwnProperty('BodyFieldParams')) {
                    for (let i = 0; i < bodyFieldParams.length; i++) {
                        if (bodyFieldParams[i].FieldName == "ReceiptOperation") {
                            bodyFieldParams[i].Fixed = "right";
                        }
                    }
                }
                _this.Utils.messageBox('用户自定义视图保存成功！', "success");
                var dp = {};
                for (var k in _this.dataSource) {
                    dp[k] = _this.dataSource[k];
                }
                _this.$emit('onDataSourceChange', dp);
            });
        },
        handleCheckAllChange(event) {
            var _this = this;
            _this.checkedItems = [];
            if (!event || (event.target && !event.target.checked)) {

                for (var i in this.colDefs.BodyFieldParams) {
                    if (_this.colDefs.BodyFieldParams[i].Configurable) {
                        _this.colDefs.BodyFieldParams[i].Visible = false;
                    }
                }
            } else {
                for (var i in _this.colDefs.BodyFieldParams) {
                    if (_this.colDefs.BodyFieldParams[i].Configurable) {

                        _this.checkedItems.push(_this.colDefs.BodyFieldParams[i].DisplayName);
                        _this.colDefs.BodyFieldParams[i].Visible = true;
                    }
                }
            }
            _this.$ajax.send("omsapi/userQueryCfg/save", "post", _this.colDefs, (data) => {
                //   _this.Event.$emit("refreshFixedList", data.Result);
                _this.Utils.messageBox("用户自定义视图保存成功！", "success");
                // _this.$router.go(0);
                //  _this.$router.replace(_this.$route.path);
            });

        },
        onResetSearch() {
            var _this = this;
            _this.$confirm(`重置后将丢失自定义查询条件，仅对当前登录账号有效，确定继续?`, `提示`,
                {
                    confirmButtonText: `确定`,
                    cancelButtonText: `取消`,
                    type: "warning"
                }).then(() => {
                    _this.$ajax.send("omsapi/userQueryCfg/reset", "get", { queryName: _this.colDefs.QueryName }, (data) => {
                        _this.Utils.messageBox('重置成功！', "success");
                        _this.onRefrush();
                    });
                });

        },
        handleCheckedChange(value) {
            var _this = this;
            let checkedCount = value.length;

            var allCount = 0;
            for (var i in _this.colDefs.BodyFieldParams) {
                if (_this.colDefs.BodyFieldParams[i].Configurable) {
                    allCount++;
                }
            }
            this.checkAll = checkedCount === allCount;
            if (_this.checkAll) {
                for (var i in _this.colDefs.BodyFieldParams) {
                    if (_this.colDefs.BodyFieldParams[i].Configurable) {
                        _this.colDefs.BodyFieldParams[i].Visible = true;
                    }
                }
            } else {
                for (var i in _this.colDefs.BodyFieldParams) {
                    if (_this.colDefs.BodyFieldParams[i].Configurable) {
                        _this.colDefs.BodyFieldParams[i].Visible = false;
                        for (var j in value) {

                            if (_this.colDefs.BodyFieldParams[i].DisplayName == value[j]) {
                                _this.colDefs.BodyFieldParams[i].Visible = true;
                            }
                        }
                    }

                }
            }
            _this.$ajax.send("omsapi/userQueryCfg/save", "post", _this.colDefs, (data) => {
                //   _this.Event.$emit("refreshFixedList", data.Result);
                _this.Utils.messageBox("用户自定义视图保存成功！", "success");
                // _this.$router.go(0);
                //  _this.$router.replace(_this.$route.path);
            });

        },
        showTableCol: function (e) {
            this.tabCol = !this.tabCol
        },
        onClickOutside(event) {
            this.tabCol = false;
        },
        addSearch(col) {
            var _this = this;
            col.Queryable = !col.Queryable;

            _this.$ajax.send("omsapi/userQueryCfg/save", "post", _this.colDefs, (data) => {
                //   _this.Event.$emit("refreshFixedList", data.Result);
                _this.Utils.messageBox("用户自定义视图保存成功！", "success");
                // _this.$router.go(0);
                //  _this.$router.replace(_this.$route.path);
            });
        },
        sortUp(col) {
            var _this = this;
            var curIndex = -1;
            if (_this.dataSource.ColDefs && _this.dataSource.ColDefs.BodyFieldParams) {
                for (var i = 0; i < _this.dataSource.ColDefs.BodyFieldParams.length; i++) {
                    _this.dataSource.ColDefs.BodyFieldParams[i].SortNo = i;
                    if (i == 0) {
                        _this.dataSource.ColDefs.BodyFieldParams[i].Fixed = true;
                    }
                    else {
                        _this.dataSource.ColDefs.BodyFieldParams[i].Fixed = false;
                    }
                    if (_this.dataSource.ColDefs.BodyFieldParams[i].FieldName == col.FieldName) {
                        curIndex = i;

                    }
                }

                if (curIndex > 0) {
                    _this.dataSource.ColDefs.BodyFieldParams[curIndex].SortNo = curIndex - 1;
                    _this.dataSource.ColDefs.BodyFieldParams[curIndex - 1].SortNo = curIndex;
                    var tempCol = _this.dataSource.ColDefs.BodyFieldParams[curIndex];
                    _this.dataSource.ColDefs.BodyFieldParams[curIndex] = _this.dataSource.ColDefs.BodyFieldParams[curIndex - 1];
                    _this.dataSource.ColDefs.BodyFieldParams[curIndex - 1] = tempCol;
                    var newParam = [];
                    for (var i = 0; i < _this.dataSource.ColDefs.BodyFieldParams.length; i++) {
                        newParam[i] = _this.dataSource.ColDefs.BodyFieldParams[i];
                    }
                    _this.dataSource.ColDefs.BodyFieldParams = newParam;
                    var dp = {};
                    for (var k in _this.dataSource) {
                        dp[k] = _this.dataSource[k];
                    }



                    _this.$ajax.send("omsapi/userQueryCfg/save", "post", _this.dataSource.ColDefs, (data) => {
                        //   _this.Event.$emit("refreshFixedList", data.Result);
                        _this.Utils.messageBox("用户自定义视图保存成功！", "success");
                        this.$emit('onDataSourceChange', dp);
                        //    _this.$router.go({
                        //    path:  _this.$router.path,
                        //    query: {
                        //        t: + new Date()
                        //    }
                        //});
                        //  _this.$router.go(0);
                        //_this.$router.replace(_this.$route.path);
                    });
                }
            }



        },
        sortDown(col) {
            var _this = this;
            var curIndex = -1;
            if (_this.dataSource.ColDefs && _this.dataSource.ColDefs.BodyFieldParams) {
                for (var i = 0; i < _this.dataSource.ColDefs.BodyFieldParams.length; i++) {
                    _this.dataSource.ColDefs.BodyFieldParams[i].SortNo = i;
                    if (i == 0) {
                        _this.dataSource.ColDefs.BodyFieldParams[i].Fixed = true;
                    }
                    else {
                        _this.dataSource.ColDefs.BodyFieldParams[i].Fixed = false;
                    }
                    if (_this.dataSource.ColDefs.BodyFieldParams[i].FieldName == col.FieldName) {
                        curIndex = i;

                    }
                }
                if (curIndex > -1 && curIndex < _this.dataSource.ColDefs.BodyFieldParams.length - 1) {
                    _this.dataSource.ColDefs.BodyFieldParams[curIndex].SortNo = curIndex + 1;
                    _this.dataSource.ColDefs.BodyFieldParams[curIndex + 1].SortNo = curIndex;
                    var tempCol = _this.dataSource.ColDefs.BodyFieldParams[curIndex];
                    _this.dataSource.ColDefs.BodyFieldParams[curIndex] = _this.dataSource.ColDefs.BodyFieldParams[curIndex + 1];
                    _this.dataSource.ColDefs.BodyFieldParams[curIndex + 1] = tempCol;
                    var newParam = [];
                    for (var i = 0; i < _this.dataSource.ColDefs.BodyFieldParams.length; i++) {
                        newParam[i] = _this.dataSource.ColDefs.BodyFieldParams[i];
                    }
                    _this.dataSource.ColDefs.BodyFieldParams = newParam;
                    var dp = {};
                    for (var k in _this.dataSource) {
                        dp[k] = _this.dataSource[k];
                    }



                    _this.$ajax.send("omsapi/userQueryCfg/editpersonallist", "post", _this.colDefs, (data) => {
                        //   _this.Event.$emit("refreshFixedList", data.Result);
                        _this.Utils.messageBox("用户自定义视图保存成功！", "success");
                        this.$emit('onDataSourceChange', dp);
                        //   _this.$router.go(0);
                        //_this.$router.replace(_this.$route.path);
                    });
                }
            }

        },
        fixedCol(col) {
            var _this = this;

            var curIndex = -1;
            col.Fixed = !col.Fixed;
            if (_this.dataSource.ColDefs && _this.dataSource.ColDefs.BodyFieldParams) {
                for (var i = 0; i < _this.dataSource.ColDefs.BodyFieldParams.length; i++) {
                    _this.dataSource.ColDefs.BodyFieldParams[i].SortNo = i;
                    if (_this.dataSource.ColDefs.BodyFieldParams[i].FieldName == col.FieldName) {
                        curIndex = i;
                        _this.dataSource.ColDefs.BodyFieldParams[i].Fixed = !col.Fixed;
                    }
                    else {
                        _this.dataSource.ColDefs.BodyFieldParams[i].Fixed = false;
                    }

                }
                if (curIndex > 0) {
                    _this.dataSource.ColDefs.BodyFieldParams[curIndex].SortNo = 0;
                    for (var i = 0; i < curIndex; i++) {
                        _this.dataSource.ColDefs.BodyFieldParams[i].SortNo = i + 1;
                    }
                    _this.dataSource.ColDefs.BodyFieldParams.splice(curIndex, 1);
                    _this.dataSource.ColDefs.BodyFieldParams.unshift(col);

                    var newParam = [];
                    for (var i = 0; i < _this.dataSource.ColDefs.BodyFieldParams.length; i++) {
                        newParam[i] = _this.dataSource.ColDefs.BodyFieldParams[i];
                    }
                    _this.dataSource.ColDefs.BodyFieldParams = newParam;
                    var dp = {};
                    for (var k in _this.dataSource) {
                        dp[k] = _this.dataSource[k];
                    }
                }


                _this.$ajax.send("omsapi/userQueryCfg/save", "post", _this.colDefs, (data) => {
                    //   _this.Event.$emit("refreshFixedList", data.Result);
                    _this.Utils.messageBox("用户自定义视图保存成功！", "success");
                    // _this.$router.go(0);
                    // _this.$router.replace(_this.$route.path);
                });
            }
        },
        addWidth(col) {
            var _this = this;

            col.Width = 600;

            _this.$ajax.send("omsapi/userQueryCfg/save", "post", _this.colDefs, (data) => {
                //   _this.Event.$emit("refreshFixedList", data.Result);
                _this.Utils.messageBox("用户自定义视图保存成功！", "success");
                // _this.$router.go(0);
                // _this.$router.replace(_this.$route.path);
            });
        },
        subWidth(col) {
            var _this = this;

            col.Width = 0;

            _this.$ajax.send("omsapi/userQueryCfg/save", "post", _this.colDefs, (data) => {
                //   _this.Event.$emit("refreshFixedList", data.Result);
                _this.Utils.messageBox("用户自定义视图保存成功！", "success");
                // _this.$router.go(0);
                // _this.$router.replace(_this.$route.path);
            });
        },
        onRefrush() {
            this.$emit("onDataSourceChange");
        },
        onToggleSearch() {
            this.showQueryCondation = !this.showQueryCondation;
            this.$emit("onToggleSearch");
        }
    },
    mounted() {
    }
}

</script>


<style lang="scss" scoped>
.selector-option {
    display: flex;

    p {
        border: 1px solid #d1dbe5 !important;
        background-color: #f3f3f3 !important;
        height: 20px;
        line-height: 18px;
        border-bottom: none !important;

        &:first-child {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }
    }

    .refrush {
        padding: 0px 10px;
        border-right: none !important;
        border-top-left-radius: 3px !important;
        cursor: pointer;

        i {
            font-weight: 700;
        }
    }

    .search {
        padding: 0px 10px;
        border-right: none !important;
        cursor: pointer;

        i {
            font-weight: 700;
        }
    }

    .lo-icon {
        padding-right: 6px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
}
</style>