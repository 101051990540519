<template>
    <div class="sort-box" v-show="isShow" v-bind:style="[styles]" v-click-outside="onClickOutside">
        <ul>
            <li v-if="FieldSortable">
                <span>排序：</span>
                <div class="sort-right">
                    <a @click="sortFilter('1')">升序</a><a @click="sortFilter('0')">降序</a>
                </div>
            </li>
            <li v-if="FieldFilterable">
                <span class="sort-font"><em>{{ FieldLabel }}</em>：</span>
                <div class="sort-right form-list">
                    <el-select v-if="fieldDef.FieldType == 3 || fieldDef.FieldType == 4 || fieldDef.FieldType == 8"
                        v-model="FieldValue" :multiple=true placeholder="请选择" size="mini">
                        <el-option v-for="item in fieldDataSource" :key="item.Value" :label="item.Label"
                            :value="item.Value">
                        </el-option>
                    </el-select>
                </div>
            </li>
            <li v-if="FieldFilterable">
                <div class="sort-btn">
                    <el-button @click="swithFilter">返回</el-button>
                    <el-button type="primary" @click="confirmFilter">确认</el-button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

import Event from '../../../../scripts/event.js'

export default {
    name: 'enumfield-filter',
    data: function () {
        return {
            isShow: false,
            isToggle: false,
            context: {},
            styles: {},
            fieldDef: Object,
            FieldLabel: "",
            FieldValue: [],
            FieldValueLabel: "",
            FieldType: '3',
            FieldName: '',
            fieldDataSource: Object,
            FieldSortable: false,
            FieldFilterable: false
        }
    },

    methods: {
        keyboard(event) {//键盘事件
            var _this = this;
            var e = event || window.event;
            var keyCode = e.keyCode || e.which;

            if (keyCode == 13) {
                //e.returnValue = false;
                setTimeout(function () {
                    if (_this.isShow) {
                        _this.confirmFilter();
                    }
                }, 300);
            }
        },
        sortFilter: function (order) {
            this.swithFilter();
            this.context.PageIndex = 1;
            // (!this.context.orderBy) && (this.context.orderBy = {});
            // this.context.OrderBy = {};
            // this.context.OrderBy[this.FieldName] = order;

            this.context.OrderBy = [];
            this.context.OrderBy.push({
                FieldName: this.FieldName,
                OrderByType: order
            });
            this.parent.$emit('onPageChange', this.context);
        },
        confirmFilter: function () {
            this.swithFilter();
            this.context.PageIndex = 1;
            if (!this.context.Params) {
                this.context.Params = {};
            }
            let obj = {};
            obj.FieldName = this.FieldName;
            obj.FieldValue = this.FieldValue;
            if (obj.FieldValue.toString() != '') {
                let values = obj.FieldValue.toString().split(',');
                for (var i = 0; i < values.length; i++) {
                    let s = {};
                    s = this.fieldDataSource.find((item) => {
                        return item.Value == values[i];
                    });
                    if (obj.FieldValueLabel == undefined) { obj.FieldValueLabel = ""; }
                    if (obj.FieldValueLabel == "") {
                        obj.FieldValueLabel += s.Label;
                    }
                    else { obj.FieldValueLabel += "，" + s.Label; }
                }
            }
            obj.FieldLabel = this.FieldLabel;
            obj.FieldType = this.FieldType;

            // this.context.Params[this.FieldName] = obj;
            this.$set(this.context.Params, this.FieldName, obj)

            this.parent.$emit('onPageChange', this.context);

        },
        swithFilter: function () {
            this.isShow = !this.isShow;
            $('.el-table__fixed-header-wrapper').css('z-index', '6');
        },
        onClickOutside(event) {
            this.isShow = false;
            if (this.isToggle) {
                this.isShow = true;
                this.isToggle = false;
            }
        }

    },
    mounted: function () {
        var _this = this;
        Event.$on("enumfilter", function ({ e, column, context, colDef, parent }) {

            _this.parent = parent;
            _this.fieldDef = colDef;
            _this.FieldLabel = colDef.DisplayName;
            // _this.FieldName = colDef.QueryField;
            _this.FieldName = colDef.FieldName; // 和后端保持一致
            _this.FieldSortable = colDef.Sortable;
            _this.FieldFilterable = colDef.Filterable;
            _this.context = context;
            _this.FieldValue = [];
            _this.FieldValueLabel = "";
            if (_this.context.Params && _this.context.Params[_this.FieldName]) {

                _this.FieldValue = _this.context.Params[_this.FieldName].FieldValue;
                if (_this.context.Params[_this.FieldName].FieldValueLabel == undefined) {
                    _this.context.Params[_this.FieldName].FieldValueLabel = "";
                }
                _this.FieldValueLabel = _this.context.Params[_this.FieldName].FieldValueLabel;

            }

            _this.isToggle = true;
            _this.swithFilter();
            console.log(e.clientX)
            if ((window.innerWidth - e.clientX) < 250) {
                _this.styles = { top: +e.clientY + 10 + 'px', right: '5px', left: 'auto' }
            } else {
                _this.styles = { top: +e.clientY + 10 + 'px', left: +e.clientX - 10 + 'px' }
            }
            if (colDef.FieldType == 3 || colDef.FieldType == 4 || colDef.FieldType == 8) {
                _this.fieldDataSource = colDef.DataSource;

            }
            // if (colDef.FieldType == 3)
            // {
            //     this.$ajax.query("api/tcfuserpersonal/getenumsource", "get", { enumName: colDef.DataSourceName }, (result) => {
            //        _this.fieldDataSource = result.Result;

            // }

            //)
            //}
        });

        document.addEventListener("keydown", this.keyboard);

    },

    beforeDestroy() {
        // Remove the Listener
        document.removeEventListener("keydown", this.keyboard);
    }

}

</script>

