import { render, staticRenderFns } from "./inlinetable.vue?vue&type=template&id=174f0bfc&scoped=true"
import script from "./inlinetable.vue?vue&type=script&lang=js"
export * from "./inlinetable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "174f0bfc",
  null
  
)

export default component.exports