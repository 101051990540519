<template>
    <span v-if="readOnly">{{ readOnlyCustomerNames }}</span>
    <el-select v-else :multiple="isMultiple" collapse-tags v-model="currentCustomerCode" placeholder="请选择"
        @change="changeCustomer" :filter-method="filter" filterable>
        <template>
            <li style="height: 30px;
            line-height: 30px;
            font-size: 13px;
            padding: 0 20px;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #606266;
            box-sizing: border-box;
            font-weight: bold;">
                <div style="padding-right:20px">
                    <span style="float: left">货主名称</span>
                    <span style="float: right;">货主编码</span>
                </div>
            </li>
        </template>
        <el-option v-for="customer in customers" :key="customer.value" :label="customer.label" :value="customer.value">
            <div style="padding-right:20px">
                <span style="float: left">{{ customer.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px; margin-left:10px">{{ customer.value }}</span>
            </div>
        </el-option>
    </el-select>
</template>
<script>
    // import Event from '@scripts/event.js'
    export default {
        name: 'ChooseCustomer',
        model: { prop: 'currentCustomerCode' },
        data: function () {
            return {
                isMultiple: false,
                customers: [],
                originalCustomers: [],
                selectedCustomers: []
            }
        },
        props: {
            loadData: { // 是否加载数据
                type: Boolean,
                default: true
            },
            pageKey: { // 一级page选择的customer键
                type: String,
                default: ''
            },
            subPageKey: { // 二级page （tab page 或者新增页面等，都叫二级page）选择的customer键
                type: Number,
                default: null
            },
            currentCustomerCode: { //选中货主编码
                type: String,
                default: ''
            },
            customerName: { //选中货主名称
                type: String,
                default: ''
            },
            canSelectAll: { // 是否可以全选
                type: Boolean,
                default: false
            },
            isRequired: { // 是否必填
                type: Boolean,
                default: true
            },
            onChange: { // 变化事件
                type: Function,
                default: null
            },
		
            readonly: { // 只读
                type: Boolean,
                default: false
            },
            emptyValue: { // 值为空时显示的字符串
                type: String,
                default: '------'
            },
        },
        mounted: function () {
            this.loadCustomers();
        },
        beforeDestroy() {
            /*页面销毁的时候要保存一些数据，就可以监听这个销毁的生命周期函数*/
            console.log('实例销毁之前');
        },
        watch: {
            loadData: function (newVal, oldVal) {
                if (newVal) {
                    this.loadCustomers();
                }
            },
            currentCustomerCode: function (newVal, oldVal) {
                var _this = this;
                if (_this.isMultiple) {//多选值
                    _this.selectedCustomers = _this.originalCustomers.filter(x => newVal.includes(x.value));
                } else {
                    _this.selectedCustomers = _this.originalCustomers.filter(x => x.value === newVal);
                }
            }
        },
        computed: {
            readOnly: function(){
                return this.$attrs.readonly === "" || this.$attrs.readonly === 'readonly' || this.readonly;
            },
            readOnlyCustomerNames: function(){
                if(this.selectedCustomers && this.selectedCustomers.length > 0){
                    return this.selectedCustomers.map(x => x.label).join();
                }
                return this.emptyValue;
            }
        },
        methods: {
            loadCustomers() { // 需确保此方便可以执行多次
                let _this = this;
                if (!_this.loadData) {
                    return;
                }
                if (_this.$attrs.multiple == "" || _this.$attrs.multiple == 'true') {
                    _this.isMultiple = true;
                }
                _this.customers = [];
                if (_this.canSelectAll) {
                    _this.customers.push({ label: '所有', value: '' });
                }
                // 同步阻塞方式加载所有customers信息
                _this.$ajax.send("omsapi/customerinfo/getcurrentcustomers", "get", {}, (data) => {
                    if (data && data.Result.length > 0) {
                        let allCustomers = data.Result.map(customer => {
                            return { label: customer.Value, value: customer.Key };
                        });
                        allCustomers.forEach(c => {
                            _this.customers.push(c);
                        });
                    }
                }, null, false);
                _this.originalCustomers = Object.assign(_this.customers);
                // 默认取第一个 (所有 或者 真正的第一个)
                var currentCCode;
                if (_this.currentCustomerCode != null && _this.currentCustomerCode.length > 0) {
                    currentCCode = _this.currentCustomerCode;
                } else {
                    var val = _this.customers.length > 0 ? _this.customers[0].value : ''
                    currentCCode = _this.isMultiple ? [val] : val;
                }
                if (_this.isRequired) {
                    _this.changeCustomer(currentCCode);
                }
            },
            changeCustomer(val) {
                let _this = this;
                if (_this.isMultiple) {//多选值
                    //传进来的val是select组件选中的value值
                    _this.selectedCustomers = this.originalCustomers.filter(x => val.includes(x.value));
                    var customerNames = _this.selectedCustomers.map(x => x.label);

                    _this.$emit("input", val);
                    _this.$emit('update:customerName', customerNames);

                    _this.onChange && _this.onChange(val);
                } else {
                    //传进来的val是select组件选中的value值
                    _this.selectedCustomers = _this.originalCustomers.filter(x => x.value === val);

                    _this.$emit("input", val);
                    _this.$emit('update:customerName', _this.selectedCustomers[0].label);

                    _this.onChange && _this.onChange(val);
                }
				this.$forceUpdate()
            },
            filter(val) {
                const enterVal = val.toLowerCase();
                this.customers = this.originalCustomers.filter(customer => {
                    return customer.label.toLowerCase().indexOf(enterVal) !== -1 || (customer.value && customer.value.toLowerCase().indexOf(enterVal) !== -1);
                })
            },
        }
    }
</script>