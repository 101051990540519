import LookPopup from './lookPopup.vue';

LookPopup.install = function (Vue) {
    Vue.mixin({
        props: {
            // 我们可以通过这个pageKey来唯一区分一个页面 (不管是一级tab page，还是页面内的二级 tab page，还是新增page，总的来说它对用户而言就是一个页面)
            // 从而我们可以通过此key关联的this.$store.state.global.pageCustomerObj[pageKey]存储当前页面选择的customer
            pageKey: {
                type: String,
                default: ''
            }
        },
        data: function(){
            return {
                watchPageKey: '', // 需要监听的货主控件page key，默认不监听
                watchPageKeySelectedCustomerCode: '', // 需要监听的货主控件page key选择的货主
            }
        },
        beforeMount: function(){
            var _this = this;
            if(_this.watchPageKey){
                // 监听列表页面上的货主控件变化 （货主控件每次发送变化，都会发送pageKey事件）
                this.Event.$on(this.watchPageKey, (currentCCode) => {
                    _this.watchPageKeySelectedCustomerCode = currentCCode;
                });
            }
        },
        methods:{
            // 恢复监控页面的货主编码
            restoreWatchPageCustomerCode(){
                if(this.watchPageKey){
                    this.$store.state.global.currentCCode = this.$store.state.global.pageCustomerObj[this.watchPageKey];
                }
            }
        }
    });
    Vue.component(LookPopup.name, LookPopup);
};

export default LookPopup;