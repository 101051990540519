<template>
    <div class="filter-box" v-if="showQueryButton">
        <div class="input-option" v-for="(col, index) in colDefs.BodyFieldParams" :key="col.QueryField"
            v-if="col.Queryable && queryParamA && queryParamA.Params && queryParamA.Params[col.QueryField]">

            <span class="fb-title">{{ col.DisplayName }}：</span>
            <input v-if="col.FieldType != 2 && col.FieldType != 3 && col.FieldType != 4 && col.FieldType != 8"
                placeholder="请输入内容" v-model="queryParamA.Params[col.QueryField].FieldValue" />
            <span v-if="col.FieldType == 2">
                <!-- queryParamA.Params[col.QueryField].FieldValueStart -->
                <el-date-picker v-if="col.FieldType == 2" v-model="startTime[index]" type="date" placeholder="选择日期">
                </el-date-picker>
                至
                <!-- queryParamA.Params[col.QueryField].FieldValueEnd -->
                <el-date-picker v-if="col.FieldType == 2" v-model="endTime[index]" type="date"
                    placeholder="选择日期"></el-date-picker>
            </span>
            <!-- queryParamA.Params[col.QueryField].FieldValue selectAll[col.FieldType-3]-->
            <el-select v-if="col.FieldType == 3 || col.FieldType == 4 || col.FieldType == 8" v-model="selectAll[index]"
                :multiple=true placeholder="请选择" size="mini">
                <el-option v-for="item in col.DataSource" :key="item.Value" :label="item.Label" :value="item.Value">
                </el-option>
            </el-select>

        </div>
        <el-button type="primary" size="small" @click="searchByCondition(false)">搜索</el-button>
        <!-- <el-button type="primary" size="small" @click="searchByCondition(true)">模糊搜索</el-button> -->
    </div>
</template>
<script>
export default {
    name: 'quick-search',
    props: {
        colDefs: {
            type: Object,
            default: function () {
                return {
                    BodyFieldParams: []
                };
            }
        },

        // 参数内容
        queryParam: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data: function () {

        return {
            queryParamA: [],
            selectAll: ['', ''],
            startTime: [''],
            endTime: [''],


        }
    },
    watch: {
        queryParam: {
            handler: function () {

                var newParam = {};
                for (var k in this.queryParam) {
                    newParam[k] = this.queryParam[k];
                }
                this.queryParamA = newParam;
                if (this.colDefs && this.colDefs.BodyFieldParams) {
                    for (var i = 0; i < this.colDefs.BodyFieldParams.length; i++) {
                        if (newParam.Params[this.colDefs.BodyFieldParams[i].QueryField] != undefined) {
                            if (this.colDefs.BodyFieldParams[i].FieldType == 3 || this.colDefs.BodyFieldParams[i].FieldType == 4 || this.colDefs.BodyFieldParams[i].FieldType == 8) {
                                this.selectAll[i] = newParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValue;
                                console.log(this.selectAll);
                            }
                            this.startTime[i] = newParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueStart;
                            this.endTime[i] = newParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueEnd;
                        }
                    }
                }
            },
            deep: true
        },
        colDefs: {
            handler: function () {
                if (!this.queryParam) {
                    this.queryParam = {};
                }
                //this.queryParam.PageIndex = 1;
                if (!this.queryParam.Params) {
                    this.queryParam.Params = {};
                }
                if (this.colDefs && this.colDefs.BodyFieldParams) {

                    for (var i = 0; i < this.colDefs.BodyFieldParams.length; i++) {

                        if (this.colDefs.BodyFieldParams[i].Queryable && !this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField]) {
                            let obj = {};
                            obj.FieldName = this.colDefs.BodyFieldParams[i].QueryField;

                            obj.FieldValueStart = "";
                            obj.FieldValueEnd = "";
                            obj.FieldLabel = this.colDefs.BodyFieldParams[i].DisplayName;
                            obj.FieldType = this.colDefs.BodyFieldParams[i].FieldType;
                            if (obj.FieldType == 3 || obj.FieldType == 4 || obj.FieldType == 8) {
                                obj.FieldValue = [];
                                obj.FieldValueLabel = "";
                            }
                            else {
                                obj.FieldValue = "";
                            }
                            this.queryParam.Params[obj.FieldName] = obj;
                            if (this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField] != undefined) {
                                if (this.colDefs.BodyFieldParams[i].FieldType == 3 || this.colDefs.BodyFieldParams[i].FieldType == 4 || this.colDefs.BodyFieldParams[i].FieldType == 8) {
                                    this.selectAll[i] = this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValue;
                                }
                                this.startTime[i] = this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueStart;
                                this.endTime[i] = this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueEnd;
                            }
                        }
                    }

                }
            },
            deep: true
        }

    },

    computed: {
        showQueryButton: function () {
            if (this.colDefs && this.colDefs.BodyFieldParams) {
                for (var i = 0; i < this.colDefs.BodyFieldParams.length; i++) {
                    if (this.colDefs.BodyFieldParams[i].Queryable) {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    methods: {
        searchByCondition: function (val) {
            if (this.colDefs && this.colDefs.BodyFieldParams) {
                for (var i = 0; i < this.colDefs.BodyFieldParams.length; i++) {
                    if (this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField] != undefined && this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField] != '') {
                        if ((this.colDefs.BodyFieldParams[i].FieldType == 3 || this.colDefs.BodyFieldParams[i].FieldType == 4 || this.colDefs.BodyFieldParams[i].FieldType == 8) && (this.colDefs.BodyFieldParams[i].Visible || this.colDefs.BodyFieldParams[i].Queryable)) {

                            this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValue = this.selectAll[i];
                            this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueLabel = "";

                            if (this.selectAll[i] != undefined && this.selectAll[i].toString() != '') {
                                let values = this.selectAll[i].toString().split(',');
                                for (var j = 0; j < values.length; j++) {

                                    if (this.colDefs.BodyFieldParams[i].DataSource != null) {
                                        let s = {};

                                        s = this.colDefs.BodyFieldParams[i].DataSource.find((item) => {
                                            return item.Value == values[j];
                                        });

                                        if (this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueLabel == "") {
                                            this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueLabel += s.Label;
                                        }
                                        else {
                                            this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueLabel += "，" + s.Label;

                                        }
                                    }
                                }
                            }
                        }

                        this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueStart = this.startTime[i];

                        this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueEnd = this.endTime[i];
                    }



                }
            }
            this.queryParam.UseFastQuery = val;
            this.$emit('onPageChange', this.queryParam);
        },



    },
    mounted: function () {
        if (!this.queryParam) {
            this.queryParam = {};
        } else {
            this.queryParamA = this.queryParam;
        }
        this.queryParam.UseFastQuery = false;
        this.queryParam.PageIndex = 1;
        if (!this.queryParam.Params) {
            this.queryParam.Params = {};
        }
        if (this.colDefs && this.colDefs.BodyFieldParams) {

            for (var i = 0; i < this.colDefs.BodyFieldParams.length; i++) {

                if (this.colDefs.BodyFieldParams[i].Queryable && !this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField]) {
                    let obj = {};
                    obj.FieldName = this.colDefs.BodyFieldParams[i].QueryField;
                    obj.FieldValueStart = "";
                    obj.FieldValueEnd = "";
                    obj.FieldLabel = this.colDefs.BodyFieldParams[i].DisplayName;
                    obj.FieldType = this.colDefs.BodyFieldParams[i].FieldType;
                    if (obj.FieldType == 3 || obj.FieldType == 4 || obj.FieldType == 8) {
                        obj.FieldValue = [];
                        obj.FieldValueLabel = "";
                    }
                    else {
                        obj.FieldValue = "";
                    }
                    this.queryParam.Params[obj.FieldName] = obj;

                    if (this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField] != undefined) {
                        if (this.colDefs.BodyFieldParams[i].FieldType == 3 || this.colDefs.BodyFieldParams[i].FieldType == 4 || this.colDefs.BodyFieldParams[i].FieldType == 8) {
                            this.selectAll[i] = this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValue;
                        }
                        this.startTime[i] = this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueStart;
                        this.endTime[i] = this.queryParam.Params[this.colDefs.BodyFieldParams[i].QueryField].FieldValueEnd;
                    }
                }
            }

            console.log(this.selectAll, 'sdf');

        }

    }

}

</script>