<template>
    <div>
        <el-select v-model="selectedOptions" filterable :allow-create="customInput" :placeholder="placeholderText" @change="change"  :readonly='readonly' :disabled='disabled' @visible-change="visibleChange">
            <template v-if="datType=='Int'">
                <el-option  v-for="item in selectList" 
                    :key="item.Value"
                    :label="item.Label" 
                    :value="item.Value">
                </el-option>
            </template>
            <template v-else>
                <el-option v-for="item in selectList" 
                    :key="item.StringValue"
                    :label="item.Label" 
                    :value="item.StringValue">
                </el-option>
            </template>
        </el-select>
    </div>
</template>
<script>
export default {
    name: "DictionarySelect",
    model: { prop: 'dicValue' },
    data() {
        return {
            selectList: [],
            selectedOptions:this.dicValue
        };
    },
    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        customInput:{
            type: Boolean,
            default: false,
        },
        dicValue: [String,Number],
        datType: {
            type: String,
            default: "String",
        },
        dicTypeCode: {
            type: String,
            default: "",
        },
        placeholderText: {
            type: String,
            default: "请选择",
        },
        onChange: {
            type: Function,
            default: null,
        },
    },
    mounted:function(){
        this.loadDicList();
    },
    watch: {
        dicValue(newVal){
            this.selectedOptions = newVal;
        },
        selectedOptions (newVal,oldVal){
            this.$emit("input",this.selectedOptions )
            if(newVal!=oldVal)
                this.$emit("change",this.selectedOptions )
        },
    },
    methods: {
        change() {
            this.$emit('change', this.selectedOptions);
        },
        visibleChange(){
            this.$emit('visible-change', this.selectedOptions);
        },
        loadDicList() {
            let _this = this;
            _this.$ajax.send("omsapi/base/getDictionaryByCode", "get", { code: this.dicTypeCode }, (data) => {
                if (data && data.Result.length > 0) {
                    _this.selectList = data.Result;
                }
            }, null, false ,true);
        },
    }
};
</script>
