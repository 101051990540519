<template>
    <!-- 移除 :size="Size"，因为此属性已经被官方抛弃 -->
    <el-dialog v-if="options.destroyOnClose ? options.visible : true" :visible.sync="options.visible" :before-close="onClose" :width="options.width" :show-close="showClose" :modal-append-to-body="options.modalBody" :modal="options.modal" @open="onOpen" :append-to-body="options.body" :fullscreen="options.fullscreen" :close-on-click-modal="options.closeByModal" :destroy-on-close="options.destroyOnClose">
  
      <div slot="title" class="dialog-title">{{ Title }}</div>
      <slot></slot>
      <span slot="footer" class="dialog-footer" v-if="(options.showFooter == void 0 ? true : options.showFooter)">
        <slot name="ButtonGroupLeft"></slot>
        <el-button @click="onCancel">取消</el-button>
        <slot name="ButtonGroupCenter"></slot>
        <el-button type="primary" @click="onOk">{{ okText }}</el-button>
        <slot name="ButtonGroupRight"></slot>
        <el-button type="primary" @click="onContinue" v-if="options.showContinue">{{ continueText }}</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  export default {
    name: "DialogExt",
    data() {
      return {}
    },
    props: {
      options: {
        title: "",
        visible: false,
        fullscreen: false,
        size: '', //	Dialog 的大小	tiny/small/large/full
        showFooter: true,
        modal: true, //是否需要遮罩层
        width: '80%',
        closeByModal: false, //是否可以通过点击 modal 关闭 Dialog
        body: true,
        modalBody: true,
        showContinue: false,
        destroyOnClose: false // 关闭时销毁元素
      },
      title: '',
      okText: {
        type: String,
        default: function () {
          return '确定';
        }
      },
      continueText: {
        type: String,
        default: function () {
          return '确定并继续';
        }
      },
      showClose: {
        type: Boolean,
        default: true
      },
      confirmMsg: null,
      openDialog: null
    },
    computed: {
      Size: function () {
        return this.options.size || "tiny";
      },
      Title: function () {
        return this.options.title || this.title || "dialog";
      },
      visible: function () {
        return this.options.visible || false;
      }
    },
    watch: {
      visible: {
        handler: function () {
          if (this.options.visible)
            this.reFresh();
        },
        deep: true
      },
    },
    mounted() {
      // console.log("x1",this.options)
    },
    methods: {
      onContinue: function () {
        let _this = this;
        if (this.confirmMsg) {
          this.$confirm(this.confirmMsg, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            doOk();
          });
        } else {
          _this.options.visible = true;
          _this.$emit("onContinue", _this.options);
          _this.$emit("onOk", _this.options);
          //_this.$emit("onCompleted");
        }
      },
      onOk: function () {
        let _this = this;
        if (this.confirmMsg) {
          this.$confirm(this.confirmMsg, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            doOk();
          });
        } else {
          doOk();
        }
  
        function doOk() {
          _this.options.visible = false;
          _this.$emit("onOk", _this.options);
          _this.$emit("onCompleted");
        }
      },
      onOpen() {
        if (this.openDialog) {
          this.openDialog();
        }
      },
      onCancel: function () {
        this.options.visible = false;
        this.$emit("onCancel", this.options);
        this.$emit("onCompleted");
      },
      onClose: function () {
        this.options.visible = false;
        this.$emit("onClose", this.options);
        this.$emit("onCompleted");
      },
      reFresh: function () {
        var slot = this.$slots.default;
        if (!slot || !slot[0] || !slot[0].data) return;
  
        var ref = slot[0].data.ref || null;
        if (!ref || !slot[0].context.$refs[ref]) return;
  
        slot[0].context.$refs[ref].getList && slot[0].context.$refs[ref].getList();
        slot[0].context.$refs[ref].clearCurrentSelectedRow && slot[0].context.$refs[ref].clearCurrentSelectedRow();
      }
    }
  }
  </script>
  
  <style scoped>
  .dialog-title{
    font-size: 13px;
  }
  ::v-deep .el-dialog__header{
    background-color: #3c8ad9;
    color: white;
    font-size: 23px;
    padding: 10px 20px;
  }
  </style>