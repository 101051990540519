import Event from './event.js'
import Cookie from './cookie.js'
import Store from './store.js'
import config from './config.js'
import sha from './sha.js'
if (typeof Array.prototype.indexOf != 'function') {
	Array.prototype.indexOf = function (elt /*, from*/) {
		var len = this.length >>> 0
		var from = Number(arguments[1]) || 0
		from = from < 0 ? Math.ceil(from) : Math.floor(from)
		if (from < 0) from += len

		for (; from < len; from++) {
			if (from in this && this[from] === elt) return from
		}
		return -1
	}
}

if (typeof Array.prototype.remove != 'function') {
	Array.prototype.remove = function (val) {
		var index = this.indexOf(val)
		if (index > -1) {
			this.splice(index, 1)
		}
	}
}

if (typeof Array.prototype.map != 'function') {
	Array.prototype.map = function (fn, context) {
		var arr = []
		if (typeof fn === 'function') {
			for (var k = 0, length = this.length; k < length; k++) {
				arr.push(fn.call(context, this[k], k, this))
			}
		}
		return arr
	}
}

if (typeof Array.prototype.filter != 'function') {
	Array.prototype.filter = function (fun /*, thisp */) {
		'use strict'

		if (this === void 0 || this === null) throw new TypeError()

		var t = Object(this)
		var len = t.length >>> 0
		if (typeof fun !== 'function') throw new TypeError()

		var res = []
		var thisp = arguments[1]
		for (var i = 0; i < len; i++) {
			if (i in t) {
				var val = t[i] // in case fun mutates this
				if (fun.call(thisp, val, i, t)) res.push(val)
			}
		}

		return res
	}
}

if (typeof Array.prototype.duplicate != 'function') {
	Array.prototype.duplicate = function (compareFucn) {
		var tmp = []
		this.concat()
			.sort()
			.sort(function (a, b) {
				if (
					Object.prototype.toString.call(compareFucn) ==
					'[object Function]'
				) {
					if (compareFucn(a, b)) tmp.push(a)
				} else {
					if (a == b && tmp.indexOf(a) === -1) tmp.push(a)
				}
			})
		return tmp
	}
}

const Utils = {
	/*
        empty Guid
    */
	emptyGuid: '00000000-0000-0000-0000-000000000000',
	/*
        empty date
    */
	emptyDate: '0001-01-01T00:00:00',

	isGreaterThanZero: function (val) {
		if (typeof val === 'string') {
			var number = Number(val);
			return !isNaN(number) && number > 0;
		}
		return false;
	},

	/*
        create a new GUID
    */
	newGuid: function () {
		var d = new Date().getTime()
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				var r = (d + Math.random() * 16) % 16 | 0
				d = Math.floor(d / 16)
				return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
			}
		)
		return uuid
	},
	/*
        type:success|warning|danger|info
    */
	messageBox: function (msg, type) {
		Event.$message({
			showClose: true,
			message: msg,
			type: type || 'error',
		})
	},
	/*
        Asynchronous execution
    */
	lazy: (func, interval) => window.setTimeout(func, interval || 100),
	/*
        Confirm the dialog box.
        type:success|error|info|warning
    */
	confirm: function (obj, sucessCallback, cancelCalback) {
		Event.$confirm(obj.content || '确认执行该操作?', obj.title || '提示', {
			confirmButtonText: obj.Ok || '确定',
			cancelButtonText: obj.Cancel || '取消',
			type: obj.type || 'warning',
		})
			.then(() => {
				sucessCallback && sucessCallback()
			})
			.catch(() => {
				cancelCalback && cancelCalback()
			})
	},
	/*
   检测当前页面是否有未保存的数据
   */
	existUnSavedData: function (dataSource) {
		if (!dataSource || typeof dataSource != 'object') return false

		var eachData = function (obj) {
			for (let i in obj) {
				var type = Object.prototype.toString.call(obj[i])
				switch (type) {
					case '[object String]':
						if (
							obj[i] != '' &&
							obj[i] != '00000000-0000-0000-0000-000000000000'
						) {
							return true
						}
						break
					case '[object Array]':
						if (obj[i].length > 0) {
							return true
						}
						break
					case '[object Number]':
						if (obj[i] != 0) {
							return true
						}
						break
					case '[object Date]':
						if (obj[i] > new Date(2000, 1, 1)) {
							return true
						}
						break
					case '[object Object]':
						eachData(obj[i])
						break
					default:
						break
				}
			}
			return false
		}
		return eachData(dataSource)
	},
	/*
   清空页面数据
   */
	clearForm: function (obj) {
		var clear = function (obj) {
			if (!obj || typeof obj != 'object') return

			for (let i in obj) {
				var type = Object.prototype.toString.call(obj[i])
				switch (type) {
					case '[object Object]':
						clear(obj[i])
						break
					case '[object Array]':
						obj[i] = []
						break
					case '[object String]':
						obj[i] = ''
						break
					case '[object Number]':
						obj[i] = ''
						break
					case '[object Boolean]':
						obj[i] = false
						break
					case '[object Date]':
						obj[i] = new Date()
						break
					default:
						break
				}
			}
		}

		clear(obj)
	},
	/*
    将列表多选结果转换为主键集合
    */
	selectionsToArr: function (mulSelections, key) {
		var ids = []

		if (!mulSelections || mulSelections.length <= 0 || !key) return ids

		ids = mulSelections.map(function (item) {
			return item[key]
		})

		return ids
	},
	/*
    obj is object type
    */
	cloneObj: function (obj) {
		var str,
			newobj = obj.constructor === Array ? [] : {}

		if (typeof obj !== 'object') {
			return
		} else if (JSON) {
			;(str = JSON.stringify(obj)), //序列化对象
				(newobj = JSON.parse(str)) //还原
		} else {
			for (var i in obj) {
				newobj[i] =
					typeof obj[i] === 'object' ? cloneObj(obj[i]) : obj[i]
			}
		}
		return newobj
	},
	/*
        is function ?
    */
	isFunction: function (obj) {
		return Object.prototype.toString.call(obj) == '[object Function]'
	},
	/*
        Promise
    */
	promise: function (func) {
		var p = new Promise(function (resolve, reject) {
			//做一些异步操作
			setTimeout(function () {
				var data = (func && func()) || ''
				resolve(data)
			}, 0)
		})
		return p
	},
	/*
        是一个电话号码或手机号    
    */
	isMobile: function (val) {
		return /(^(0\d{2,3})-?(\d{7,8})$)|(^1[3-9]\d{9}$)|(^400(\d{3}\d{4}$))/.test(
			val
		)
	},
	/*
        是一个邮箱
    */
	isMail: function (val) {
		return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(val)
	},
	/*中文*/
	isChinese: function (str) {
		return /^[\u3220-\uFA29]+$/.test(str)
	},
	isObjectValueEqual: function (a, b) {
		var aProps = Object.getOwnPropertyNames(a)
		var bProps = Object.getOwnPropertyNames(b)
		if (aProps.length != bProps.length) {
			return true
		}
		for (var i = 0; i < aProps.length; i++) {
			var propName = aProps[i]
			if (a[propName] !== b[propName]) {
				return true
			}
		}
		return false
	},
	isRegularInt: function (str) {
		return /^[1-9]\d{0,8}$/.test(str) //长度限制为9位
	},
	/*
        检查两个对象是否相等
    */
	checkObjectEqual: function (a, b) {
		var strA = JSON.stringify(a),
			strB = JSON.stringify(b)
		//console.log("strA=>"+strA);
		//console.log("strB=>"+strB);
		return strA === strB
	},
	//整数
	isNumbers: function (str) {
		return /^[1-9]\d*$/.test(str)
	},
	//浮点数
	isFloat: function (str) {
		return /^[0-9]+.?[0-9]*$/.test(str)
	},
	//手机号
	isPhone: function (str) {
		return /^1[3-9]\d{9}$/.test(str)
	},
	//验证数字0-999999999.999999
	isRegularNumber: function (str) {
		return /^(0|[1-9]\d{0,8})(\.\d{1,6})?$/.test(str)
	},
	//是否是有效的编码
	isValidCode: function (str) {
		if (str === '' || str == null || str == void 0) return false

		return str.replace(/^[_\-@a-zA-Z0-9]+/, '').length == 0
	},
	/*
        扩展对象方法
    */
	extend: function (target, options) {
		return $.extend(true, target, options)
	},
	//将浮点数四舍五入，取小数点后n位
	toDecimal: function (x, e) {
		var f_x = parseFloat(x)
		if (isNaN(f_x)) {
			return ''
		}
		var t = 1
		for (; e > 0; t *= 10, e--);
		for (; e < 0; t /= 10, e++);
		return Math.round(f_x * t) / t
	},
	//首字母大写
	strFirstUpper:function(str){
		if(str){
			if(str.length==1)
				return str.toUpperCase();
			return str.slice(0,1).toUpperCase() +str.slice(1).toLowerCase()
		}
		return str
	},isEmpty:function (obj)
    {
        if(obj==null || obj=="" || obj==undefined)
        {
            return true;
        }

        var regu="^[ ]+$";
        var re=new RegExp(regu);

        if(re.test(obj))
        {
            return true;
        }
        return false;
    },//判断是否为空
	isNull: function(obj) {
		if (typeof obj == "undefined" || obj == null || obj == "") {
			return true;
		} else {
			return false;
		}
	},
	//判断是否不为空
	isNotNull: function(obj) {
		if (typeof obj == "undefined" || obj == null || obj == "") {
			return false;
		} else {
			return true;
		}
	},
	/*
        导出
    */
	export: function (urlFile) {
/* 		var elemIF = document.getElementById('exportIframe')
		if (elemIF != null) {
			document.body.removeChild(elemIF)
		}
		elemIF = document.createElement('iframe')
		elemIF.id = 'exportIframe'
		elemIF.src = encodeURI(
			this.getDomain() +
				'operations/' +
				urlFile +
				'&currentType=' +
				Store.state.global.currentType +
				'&currentCCode=' +
				Store.state.global.currentCCode
		)
		elemIF.style.display = 'none'
		document.body.appendChild(elemIF); */
		if(!(urlFile.indexOf("https://")>-1) && !(urlFile.indexOf("http://")>-1)){
		    var domain = Cookie.get(Cookie.domain);
		    if(domain){
		        urlFile =window.AppConfig.apiCustomerUrl + urlFile;
		    }else{
		        urlFile =window.AppConfig.apiBaseUrl+ urlFile;
		    }
		}
		window.location.href=urlFile;
	},
	exportExt: function (url) {
		var elemIF = document.getElementById('exportExtIframe')
		if (elemIF != null) {
			document.body.removeChild(elemIF)
		}
		elemIF = document.createElement('iframe')
		elemIF.id = 'exportExtIframe'
		elemIF.src = encodeURI(url);
		elemIF.style.display = 'none'
		document.body.appendChild(elemIF)
		window.open(url, '_blank');
	},
	/*
        导出
    */
	exportUrl: function (urlFile) {
		var domain = Cookie.get(Cookie.domain);
		if(domain){
			urlFile =window.AppConfig.apiCustomerUrl + urlFile;
		}else{
			urlFile =window.AppConfig.apiBaseUrl+ urlFile;
		}
		//window.location.href=urlFile;
		window.open(urlFile, '_blank');
	},
	orderCommand: function (command, multipleSelection) {
		var flag = true
		for (var i = 0; i < multipleSelection.length; i++) {
			var selection = multipleSelection[i]
			var type = ''
			switch (command) {
				case 'submit':
					type = '审核'
					if (
						!(
							(selection.OrderStatus == 100 || selection.OrderStatus == 105 )&&
							selection.InterceptStatus != 1
						)
					) {
						flag = false
					}
					break
				case 'revert':
					type = '取消审核'
					if (
						!(
							selection.OrderStatus == 110 &&
							selection.OrderExecutionStatus != 500
						)
					) {
						flag = false
					}
					break
				case 'issue':
					type = '下发'
					if (
						!(
							selection.OrderStatus == 110 &&
							selection.OrderExecutionStatus != 500
						)
					) {
						flag = false
					}
					break
				case 'intercept':
					type = '拦截'
					if (
						selection.OrderExecutionStatus == 300 || 
						selection.InterceptStatus == 1
					) {
						flag = false
					}
					break
				case 'cancel':
					type = '取消'
					if (
						!(
							selection.OrderStatus == 100 &&
							selection.OrderExecutionStatus != 500
						)
					) {
						flag = false
					}
					break
				case 'reissue':
					type = '补发'
					if (!(selection.OrderExecutionStatus == 200)) {
						flag = false
					}
					break
				case 'returnOrder':
					type = '退货'
					if (!(selection.OrderExecutionStatus == 200)) {
						flag = false
					}
					break
				case 'returnChangeOrder':
					type = '退换货'
					if (!(selection.OrderExecutionStatus == 200)) {
						flag = false
					}
					break
				case 'exception':
					type = '异常处理'
					if (
						!(
							selection.OrderStatus >= 200 &&
							selection.OrderExecutionStatus == 100
						)
					) {
						flag = false
					}
					break
				case 'stop':
					type = '中止'
					if (
						!(
							selection.OrderStatus >= 200 &&
							selection.OrderStatus != 211 &&
							selection.OrderStatus != 262 &&
							selection.OrderExecutionStatus == 100 &&
							selection.ExceptionCount < 1
						)
					) {
						flag = false
					}
					break
				default:
			}
			if (!flag) {
				this.messageBox(
					'订单【' + selection.OrderNo + '】不支持' + type + '操作',
					'error'
				)
				return false
			}
		}
		return true
	},
	//过滤不用的查询条件
	exportQueryParam: function(searchQueryParam) {
		var queryParam = {
			params: {},
			orderBy: searchQueryParam.orderBy,
			UseFastQuery: searchQueryParam.UseFastQuery
		};
		for (var name in searchQueryParam.params) {
			if ((searchQueryParam.params[name].fieldValue != undefined && searchQueryParam.params[name]
					.fieldValue != "") ||
				(searchQueryParam.params[name].fieldValueStart != undefined && searchQueryParam.params[name]
					.fieldValueStart != "") ||
				(searchQueryParam.params[name].fieldValueEnd != undefined && searchQueryParam.params[name]
					.fieldValueEnd != "")) {
				queryParam.params[name] = searchQueryParam.params[name];
			}
		}
		return queryParam;
	},

	/*
        获取二级域名
    */
	getDomain: function () {
		if (config && config.subdomain) {
			return config.subdomain + '/'
		}
		return ''
	},
	getToken:function(){
		return sha.getToken();
	},
	getSubDomain:function(){
		return Cookie.get(Cookie.domain);
	},
	getViewDomain:function() {
		var pathName = window.location.pathname.substring(1);
		pathName = pathName.replace(`${config.subdomain}/login`, '');
		pathName = pathName.replace(`${config.subdomain}/home`, '');
		pathName = pathName.replace(config.subdomain, '');
		if (pathName.indexOf('/') == 0) {
			pathName = pathName.substring(1);
		}
		if (pathName.indexOf('/') < 0) {
			return pathName;
		}
		pathName = pathName == '' ? '' : pathName.substring(0, pathName.indexOf('/'));
		return pathName;
	},
	/**
	 * 递归删除children中长度为空的数组
	 */
	iterationDelateMenuChildren: function(arr){
		if (arr.length) {
			for (var i=0; i<arr.length; i++) {
			  if (arr[i].children && arr[i].children.length) {
				this.iterationDelateMenuChildren(arr[i].children)
			  } else {
				delete arr[i].children
			  }
			}
		  }
		  return arr
	},
	initialWebsite(website){
		if(!website) return;
		//修改icon
		if(website.IconLogo){
			var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
			link.type = 'image/x-icon';
			link.rel = 'shortcut icon';
			link.href = website.IconLogo;
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		//修改title
		if(website.Title){
			document.title = website.Title;
		}
	},
	handleTree(data, id, parentId, children) {
		let config = {
		  id: id || 'id',
		  parentId: parentId || 'parentId',
		  childrenList: children || 'children'
		};
	   
		var childrenListMap = {};
		var nodeIds = {};
		var tree = [];
	   
		for (let d of data) {
		  let parentId = d[config.parentId];
		  if (childrenListMap[parentId] == null) {
			childrenListMap[parentId] = [];
		  }
	   
		  nodeIds[d[config.id]] = d;
		  childrenListMap[parentId].push(d);
		}
	   
		for (let d of data) {
		  let parentId = d[config.parentId];
		  if (nodeIds[parentId] == null) {
			tree.push(d);
		  }
		}
	   
		for (let t of tree) {
		  adaptToChildrenList(t);
		}
	   
	   
		function adaptToChildrenList(o) {
		  if (childrenListMap[o[config.id]] !== null) {
			o[config.childrenList] = childrenListMap[o[config.id]];
		  }
	   
		  if (o[config.childrenList]) {
			for (let c of o[config.childrenList]) {
			  adaptToChildrenList(c);
			}
		  }
		}
		return tree;
	},
	arrayUnion(arrays) {  
		//使用 Map 来存储所有唯一对象，key 参数用于指定判断重复的属性  
		const uniqueObjects = new Map();  
		
		//遍历所有数组并将它们的对象添加到 Map 中  
		arrays.forEach(array => { 
		  if(array && array.length>0){
			array.forEach(obj => {  
				uniqueObjects.set(obj, obj);  
			  });  
		  }
		});  
		
		//将 Map 的值转换回数组  
		return Array.from(uniqueObjects.values());  
	},
	arrayUnion(arrays, key) {  
		//使用 Map 来存储所有唯一对象，key 参数用于指定判断重复的属性  
		const uniqueObjects = new Map();  
		
		//遍历所有数组并将它们的对象添加到 Map 中  
		arrays.forEach(array => { 
		  if(array && array.length>0){
			array.forEach(obj => {  
				uniqueObjects.set(obj[key], obj);  
			  });  
		  }
		});  
		
		//将 Map 的值转换回数组  
		return Array.from(uniqueObjects.values());  
	},
	getLocation(address){
		if(!address) return ['',''];
		var myGeo=new AMap.Geocoder({});
		myGeo.getLocation(address, function (status, result) {
			if (status === 'complete' && result.geocodes.length) {
				var point = result.geocodes[0].location;
				return [point.lng,point.lat];
			}else{
				return ['',''];
			}
		})
	},
	setLocation(row){
		var address = row.ProvinceName + row.CityName + row.CountyName + row.AddressDetail;
		if(!address) return;
		var myGeo=new AMap.Geocoder({});
		myGeo.getLocation(address, function (status, result) {
			if (status === 'complete' && result.geocodes.length) {
				var point = result.geocodes[0].location;
				row.Longitude = point.lng;
				row.Latitude = point.lat;
			}
		})
	}
}

/*
    全局自定义指令
*/
const CustomDirectives = {
	install: function (Vue) {
		// 注册一个全局自定义指令 v-right-code
		Vue.directive('RightCode', {
			isLiteral: true,
			// 当绑定元素插入到 DOM 中。
			inserted: function (el, binding, vnode, oldVnode) {
				var global = vnode.componentInstance.$store.state.global,
					store = vnode.componentInstance.$store,
					ajax = vnode.componentInstance.$ajax
				if (
					!global.pageRightCodes ||
					global.pageRightCodes.length == 0
				) {
					ajax.send(
						window.AppConfig.apiBaseUrl+'omsapi/sysuser/getrightcodes',
						'get',
						{},
						(data) => {
							var data = data.Result
							if (data && data.length > 0)
								store.commit('setRightCodes', data)
						},
						() => {},
						false
					)
				}
				var bindValue = binding.value;
				var code = binding.expression.replace(new RegExp("'", "gm"), "");
				store.commit('hasRightCode', code);
				if (!global.hasRight)
					$(el).remove();
			},
		})
	},
}

export default {
	install: function (Vue) {
		Object.defineProperty(Vue.prototype, 'Utils', { value: Utils })
		Object.defineProperty(Vue.prototype, 'Event', { value: Event })
		Object.defineProperty(Vue.prototype, 'Cookie', { value: Cookie })

		//注册自定义指令
		Vue.use(CustomDirectives)
	},
}
