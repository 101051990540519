<template>
    <el-input v-if="lottableAttr.ControlType==2||lottableAttr.ControlType==3" v-model="vmodel.value" :maxlength="16" @blur="onBlur" :disabled="disabled" placeholder="支持9位整数,6位小数"></el-input>
    <el-select v-else-if="lottableAttr.ControlType==4" v-model="vmodel.value" :maxlength="50" :disabled="disabled" size="mini">
        <el-option v-for="item in lottableAttr.DataSource"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
    </el-select>
    <el-date-picker v-else-if="lottableAttr.ControlType==5" v-model="vmodel.value" type="date" :disabled="disabled"></el-date-picker>
    <el-input v-else v-model="vmodel.value" :maxlength="256" :disabled="disabled"></el-input>
</template>
<script>
    export default {
        name:"DynamicCotrol",
        data(){
            return {
            }
        },
        props: {
            lottableAttr: {
            },
            vmodel: {
                type: Object,
                default: function () {
                    return {
                        value:''
                    }
                }
            },//绑定值
     disabled: false,
        },
        methods: {
            onBlur: function () {
                if (this.disabled) return;
                if ((this.lottableAttr.ControlType * 1 == 2)) {
                    if (!this.Utils.isNumbers(this.vmodel.value)) {
                        this.vmodel.value = '';
                        return;
                    }
                    //整数验证:支持9位整数
                    if ((this.vmodel.value.length + '').length > 9) {
                        this.vmodel.value = '';
                        return;
                    }
                } else if ((this.lottableAttr.ControlType * 1 == 3)) {
                    if (!this.Utils.isRegularNumber(this.vmodel.value)) {
                        this.vmodel.value = '';
                        return;
                    }
                }
            }
        }
    }
</script>