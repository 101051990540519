import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'
import Cookie from './cookie.js'
import config from '@/scripts/config.js'

//管理端路由配置
var routes = [
    { path: '/login', component: resolve => require(['../components/login/login.vue'], resolve) },
    { path: '/ssohome', component: resolve => require(['../components/login/ssohome.vue'], resolve) },
    { path: '/callback', component: resolve => require(['../components/login/callback.vue'], resolve) },
    { path: '/silent', component: resolve => require(['../components/login/silent.vue'], resolve) },
    {
        //path: '/', component: resolve => require(['@/components/home/home.vue'], resolve),
        path: '/', component: () => import('@/components/home/home.vue'),
        children: [
            { path: '/home', component: resolve => require(['../components/business/workbench/workbench.vue'], resolve) },

            //基础数据管理 
            { path: '/system/definefieldmanage', component: resolve => require(['../components/business/system/definefieldmanage/definefield.vue'], resolve) },
            { path: '/system/district', component: resolve => require(['../components/business/system/district/index.vue'], resolve) },//行政区管理
            { path: '/system/sysdictionary', component: resolve => require(['../components/business/system/sysdictionary/index.vue'], resolve) },//数据字典

            //公告管理
            { path: '/message/msgnotice', component: resolve => require(['@/components/business/message/msgnotice/index.vue'], resolve) },

            //系统管理
            { path: '/system/sysuser', component: resolve => require(['../components/business/system/user/index.vue'], resolve) },//用户
            { path: '/system/sysrole', component: resolve => require(['../components/business/system/role/index.vue'], resolve) },//角色
            { path: '/system/sysinterfacelog', component: resolve => require(['../components/business/system/interfacelog/index.vue'], resolve) },//接口日志
            { path: '/sysinterface/config', component: resolve => require(['../components/business/system/apiconfig/index.vue'], resolve) },//接口配置
            { path: '/system/sysexternalsystem', component: resolve => require(['../components/business/system/externalsystem/index.vue'], resolve) },//系统定义
            { path: '/system/syslog', component: resolve => require(['../components/business/system/syslog/index.vue'], resolve) },//日志管理
            { path: '/system/sysmenu', component: resolve => require(['../components/business/system/sysmenu/index.vue'], resolve) },//日志管理
            { path: '/system/sysqueuelog', component: resolve => require(['../components/business/system/queuelog/index.vue'], resolve) },//队列日志
            { path: '/system/sysconfig', component: resolve => require(['../components/business/system/sysconfig/index.vue'], resolve) },//系统配置
            { path: '/system/syspasswordconfig', component: resolve => require(['../components/business/system/syspasswordconfig/index.vue'], resolve) },//密码策略
           
            //商务管理
            { path: '/member/enterprise', component: resolve => require(['../components/business/operation/operatorinfo/index.vue'], resolve) },//客户
            { path: '/business/industry', component: resolve => require(['../components/business/industry/index.vue'], resolve) },//行业
            //{ path: '/member/customer', component: resolve => require(['../components/business/customerinfo/index.vue'], resolve) },//货主
            // { path: '/member/deliveryproject', component: resolve => require(['../components/business/project/deliveryproject/index.vue'], resolve) },//配送项目

            //租户管理
            { path: '/tenant/product', component: resolve => require(['../components/business/tenant/product/index.vue'], resolve) },
            { path: '/tenant/system', component: resolve => require(['../components/business/tenant/system/index.vue'], resolve) },
            { path: '/tenant/middleware', component: resolve => require(['../components/business/tenant/middleware/index.vue'], resolve) },
            { path: '/tenant/server', component: resolve => require(['../components/business/tenant/server/index.vue'], resolve) },

            //开发管理
            { path: '/developer/cacheinfo', component: resolve => require(['../components/business/developer/cache/index.vue'], resolve) },
        ]
    }

];

//客户路由配置
var customerRoutes=[
    { path: '/login', component: resolve => require(['../components/login/login.vue'], resolve) },
    { 
        path: '/', component: resolve => require(['../components/home/customerhome.vue'], resolve), 
        children:[
            { path: '/home', component: resolve => require(['../components/business/workbench/customerworkbench.vue'], resolve) },
            { path: '/system/sysdictionary', component: resolve => require(['../components/business/system/sysdictionary/index.vue'], resolve) },//数据字典
            { path: '/system/enterpriseuser', component: resolve => require(['../components/enterprise/sysuser/index.vue'], resolve) },//用户
            { path: "/system/enterpriserole", component: resolve => require(['../components/enterprise/sysrole/index.vue'], resolve) },//客户端角色
            { path: '/system/clientlog', component: resolve => require(['../components/business/system/syslog/index.vue'], resolve) },//客户端操作日志
			{ path: '/system/signinlog', component: resolve => require(['../components/business/system/sysloginlog/index.vue'], resolve) },//客户端操作日志
            { path: '/system/organization', component: resolve => require(['../components/enterprise/system/organization/index.vue'], resolve) },//客户端人力组织
            { path: '/system/logisticsorg', component: resolve => require(['../components/enterprise/system/logisticsorg/index.vue'], resolve) },//客户端物流组织
            { path: '/sysmtem/ruleofmetadata', component: resolve => require(['../components/enterprise/system/ruleofmetadata/index.vue'], resolve) },//数据对象配置
			{ path: '/sysmtem/template', component: resolve => require(['../components/enterprise/system/template/index.vue'], resolve) },//模板管理
            { path: '/system/exportjobinfo', component: resolve => require(['../components/enterprise/system/exportjobinfo/index.vue'], resolve) },//自动导出配置
            { path: '/system/exportdatalist', component: resolve => require(['../components/enterprise/system/exportdata/index.vue'], resolve) },//导出数据
            { path: '/system/sysinterfacelog', component: resolve => require(['../components/business/system/interfacelog/index.vue'], resolve) },//接口日志
            { path: '/system/sysqueuelog', component: resolve => require(['../components/business/system/queuelog/index.vue'], resolve) },//队列日志
            { path: '/interface/config', component: resolve => require(['../components/enterprise/system/clientapiconfig/index.vue'], resolve) },//客户端接口配置
            { path: '/external/external', component: resolve => require(['../components/business/system/externalaccess/index.vue'], resolve) },//系统接入
            { path: '/system/staff', component: resolve => require(['../components/business/system/staff/index.vue'], resolve) },//用户管理
            { path: '/system/message', component: resolve => require(['../components/enterprise/system/message/message/index.vue'], resolve) },//消息管理
            { path: '/system/messagetemplate', component: resolve => require(['../components/enterprise/system/message/template/index.vue'], resolve) },//消息模板
            { path: '/system/notice', component: resolve => require(['../components/enterprise/system/message/notice/index.vue'], resolve) },//公告管理
            { path: '/system/logisticsnode', component: resolve => require(['../components/enterprise/rule/ruleoflogisticsnode/index.vue'], resolve) },//物流节点映射 
            { path: '/system/systemcode', component: resolve => require(['../components/enterprise/system/systemcode/index.vue'], resolve) },//编码管理
		 
		    //档案管理
		    { path: "/customer/customershop", component: resolve => require(['../components/business/filemanage/customershop/index.vue'], resolve) },//货主门店
			{ path: "/customer/recievecustomeraddress", component: resolve => require(['../components/business/filemanage/recieveaddress/index.vue'], resolve) },//收货地址
			{ path: "/customer/sendercustomeraddress", component: resolve => require(['../components/business/filemanage/senderaddress/index.vue'], resolve) },///发货地址
			{ path: "/customer/customershopaddress", component: resolve => require(['../components/business/filemanage/customershopaddress/index.vue'], resolve) },//门店地址
            { path: "/base/customervendor", component: resolve => require(['../components/enterprise/customervendor/index.vue'], resolve) },
            { path: '/basicinfo/productclassify', component: resolve => require(['../components/enterprise/productclassify/index.vue'], resolve) },//商品分类
            { path: '/member/serviceprovider', component: resolve => require(['../components/business/member/serviceprovider/index.vue'], resolve) },//仓储服务商
            { path: '/member/shipprovider', component: resolve => require(['../components/business/member/shipprovider/index.vue'], resolve) },//承运商
            { path: '/member/warehouse', component: resolve => require(['../components/business/warehouse/index.vue'], resolve) },//仓库
            { path: '/member/project', component: resolve => require(['../components/business/project/project/index.vue'], resolve) },//项目管理
			{ path: '/material/auxiliarymaterial', component: resolve => require(['../components/enterprise/material/auxiliarymaterial/index.vue'], resolve) },//仓储项目
			

            { path: '/customer/customerinfo', component: resolve => require(['../components/enterprise/customer/customerinfo/index.vue'], resolve) },//货主信息
			//{ path: '/customer/recievecustomeraddress', component: resolve => require(['../components/enterprise/customer/recieveaddress/index.vue'], resolve) },//收货地址
            { path: '/customer/sendercustomeraddress', component: resolve => require(['../components/enterprise/customer/senderaddress/index.vue'], resolve) },//发货地址
            //{ path: '/customer/customershopaddress', component: resolve => require(['../components/enterprise/customer/customershopaddress/index.vue'], resolve) },//店铺地址
            { path: '/product/productinfo', component: resolve => require(['../components/enterprise/product/productinfo/index.vue'], resolve) },//商品管理
            { path: '/product/recallproductInfo', component: resolve => require(['../components/enterprise/product/recallproductInfo/index.vue'], resolve) },//召回商品资料
            { path: '/product/productbom', component: resolve => require(['../components/enterprise/product/bom/index.vue'], resolve) },//商品BOM管理
            { path: '/product/recallproduct', component: resolve => require(['../components/enterprise/product/recallproductInfo/index.vue'], resolve) },//召回商品资料
            
            { path: '/customer/customershop', component: resolve => require(['../components/enterprise/customer/customershop/index.vue'], resolve) },//店铺管理
            { path: '/contract/contractinfo', component: resolve => require(['../components/business/contract/index.vue'], resolve) },//合同管理 
            { path: '/vehicle/vehicletype', component: resolve => require(['../components/enterprise/vehicle/index.vue'], resolve) },//车型管理
            { path: '/device/deviceinfo', component: resolve => require(['../components/enterprise/deviceinfo/index.vue'], resolve) },//设备
            { path: '/site/siteinfo', component: resolve => require(['../components/enterprise/site/siteinfo/index.vue'], resolve) },//站点
			{ path: '/system/district', component: resolve => require(['../components/business/system/district/index.vue'], resolve) },//行政区管理
			{ path: '/model/modelinfo', component: resolve => require(['../components/enterprise/model/index.vue'], resolve) },//型号

            //业务规则
            { path: '/rule/disassembly', component: resolve => require(['../components/enterprise/rule/ruletoorderdisassembly/index.vue'], resolve) },//店铺管理
            { path: '/rule/audit', component: resolve => require(['../components/enterprise/rule/ruleorderaudit/index.vue'], resolve) },//店铺管理
            { path: '/rule/otherrule', component: resolve =>require(['../components/enterprise/rule/otherrule/index.vue'], resolve) },//全局规则
            { path: '/rule/orderwt', component: resolve => require(['../components/enterprise/rule/ruletoorderwt/index.vue'], resolve) },//仓配联动规则
            { path: '/rule/orderconnect', component: resolve => require(['../components/enterprise/rule/orderconnect/index.vue'], resolve) },//单据衔接规则 
            { path: '/rule/member', component: resolve => require(['../components/enterprise/rule/rulemember/index.vue'], resolve) },//分配承运商规则 
            { path: '/rule/warehouse', component: resolve => require(['../components/enterprise/rule/rulewarehouse/index.vue'], resolve) },//分配仓库规则 

            //系统配置
            { path: '/basic/lotmanage', component: resolve => require(['../components/enterprise/stock/lotmanage/index.vue'], resolve) },//批属性管理

            //库存管理
            { path: '/stock/stockadjust', component: resolve => require(['../components/enterprise/stock/stockadjust/index.vue'], resolve) },//库存日志
            { path: '/stock/stocklock', component: resolve => require(['../components/enterprise/stock/stocklock/index.vue'], resolve) },//库存锁定查询
            { path: '/stock/fullwarehousewarn', component: resolve => require(['../components/enterprise/stock/fullwarehousewarn/index.vue'], resolve) },//全仓预警
            { path: '/stock/branchwarehousewarn', component: resolve => require(['../components/enterprise/stock/branchwarehousewarn/index.vue'], resolve) },//分仓预警
            { path: '/stock/stockinfo', component: resolve => require(['../components/enterprise/stock/stockinfo/index.vue'], resolve) },//分仓库存管理
            { path: '/stock/summarystockinfo', component: resolve => require(['../components/enterprise/stock/summarystockinfo/index.vue'], resolve) },//汇总库存管理
            { path: '/stock/stockcompare', component: resolve => require(['../components/enterprise/stock/stockcompare/index.vue'], resolve) },//库存对账单
            { path: '/stock/stockcompareitem', component: resolve => require(['../components/enterprise/stock/stockcompareitem/index.vue'], resolve) },//库存对账差异
            { path: '/stock/stockpredist', component: resolve => require(['../components/enterprise/stock/stockrelation/index.vue'], resolve) },//库存预分配
            
            //订单管理
            {path:'/order/standardorder', component: resolve => require(['../components/enterprise/order/standardorder/index.vue'], resolve) },
            {path:'/dispatch/inbound', component: resolve => require(['../components/enterprise/order/inbounddisporder/index.vue'], resolve) },
            {path:'/dispatch/outbound', component: resolve => require(['../components/enterprise/order/outbounddisporder/index.vue'], resolve) },
            {path:'/dispatch/transport', component: resolve => require(['../components/enterprise/order/transportdisporder/index.vue'], resolve) },
            {path:'/execute/inbound', component: resolve => require(['../components/enterprise/order/inboundexecorder/index.vue'], resolve) },
            {path:'/execute/outbound', component: resolve => require(['../components/enterprise/order/outboundexecorder/index.vue'], resolve) },
            {path:'/execute/transport', component: resolve => require(['../components/enterprise/order/transportexecorder/index.vue'], resolve) },
			{path:'/order/storeprocess', component: resolve => require(['../components/enterprise/order/storeprocess/index.vue'], resolve) }, //加工单

            //GSP管理
            {path:'/gsp/complaint', component: resolve => require(['../components/enterprise/gsp/gspcomplaint/index.vue'], resolve) },
            {path:'/gsp/qualitytrack', component: resolve => require(['../components/enterprise/gsp/gspqualitytrack/index.vue'], resolve) },
            {path:'/gsp/accident', component: resolve => require(['../components/enterprise/gsp/gspaccident/index.vue'], resolve) },
            {path:'/gsp/vpcheck', component: resolve => require(['../components/enterprise/gsp/gspvpcheck/index.vue'], resolve) },
            {path:'/gsp/adrreport', component: resolve => require(['../components/enterprise/gsp/gspadrreport/index.vue'], resolve) },
            {path:'/gsp/stopsale', component: resolve => require(['../components/enterprise/gsp/gspstopsale/index.vue'], resolve) },
            {path:'/gsp/stopsaleaudit', component: resolve => require(['../components/enterprise/gsp/gspstopsaleaudit/index.vue'], resolve) },
            {path:'/gsp/stopsalecancel', component: resolve => require(['../components/enterprise/gsp/gspstopsalecancel/index.vue'], resolve) },
			
			
			//行业管理
			 {path:'/businessconfig/webcontent', component: resolve => require(['../components/enterprise/system/websiteconfig/index.vue'], resolve) },
		],
		
    },
]


var vPath = `/${config.subdomain}`;
var vRoutes = new Array();
if (vPath != '') {
    for (let i = 0; i < routes.length; i++) {
        let vRoute = {};
        vRoute.path = vPath + routes[i].path;
        vRoute.component = routes[i].component;

        if (routes[i].children && routes[i].children.length > 0) {
            vRoute.children = new Array();
            for (let j = 0; j < routes[i].children.length; j++) {
                let vcRoute = {};
                vcRoute.path = vPath + routes[i].children[j].path;
                vcRoute.component = routes[i].children[j].component;
                vRoute.children.push(vcRoute);
            }
        }
        vRoutes.push(vRoute);
    }
}
var customerPath=`/${config.subdomain}`;
function getDomain(path) {
    var pathName = path.substring(1);
    pathName = pathName.replace(`${config.subdomain}/login`, '');
    pathName = pathName.replace(`${config.subdomain}/home`, '');
    pathName = pathName.replace(config.subdomain, '');
    if (pathName.indexOf('/') == 0) {
        pathName = pathName.substring(1);
    }

    if (pathName.indexOf('/') < 0) {
        return pathName;
    }

    pathName = pathName == '' ? '' : pathName.substring(0, pathName.indexOf('/'));
    return pathName;
}

vPath = vPath + '/operations';
if (vPath != '') {
    for (let i = 0; i < routes.length; i++) {
        let vRoute = {};
        vRoute.path = vPath + routes[i].path;
        vRoute.component = routes[i].component;

        if (routes[i].children && routes[i].children.length > 0) {
            vRoute.children = new Array();
            for (let j = 0; j < routes[i].children.length; j++) {
                let vcRoute = {};
                vcRoute.path = vPath + routes[i].children[j].path;
                vcRoute.component = routes[i].children[j].component;
                vRoute.children.push(vcRoute);
            }
        }
        vRoutes.push(vRoute);
    }
}


if(customerPath!=''){
    for (let i = 0; i < customerRoutes.length; i++) {
        let vRoute = {};
        vRoute.path = customerPath + customerRoutes[i].path;
        vRoute.component = customerRoutes[i].component;

        if (customerRoutes[i].children && customerRoutes[i].children.length > 0) {
            vRoute.children = new Array();
            for (let j = 0; j < customerRoutes[i].children.length; j++) {
                let vcRoute = {};
                vcRoute.path = customerPath + customerRoutes[i].children[j].path;
                vcRoute.component = customerRoutes[i].children[j].component;
                vRoute.children.push(vcRoute);
            }
        }
        vRoutes.push(vRoute);
    }   
}
var domain = getDomain(window.location.pathname);
if (domain) {
    customerPath = customerPath + '/' + domain;

    for (var i = 0; i < customerRoutes.length; i++) {
        var vRoute = {};
        vRoute.path = customerPath + customerRoutes[i].path;
        vRoute.component = customerRoutes[i].component;

        if (customerRoutes[i].children && customerRoutes[i].children.length > 0) {
            vRoute.children = new Array();
            for (var j = 0; j < customerRoutes[i].children.length; j++) {
                var vcRoute = {};
                vcRoute.path = customerPath + customerRoutes[i].children[j].path;
                vcRoute.component = customerRoutes[i].children[j].component;
                vRoute.children.push(vcRoute);
            }
        }
        vRoutes.push(vRoute);
    }
}

routes = routes.concat(vRoutes);
const router = new VueRouter({ mode: 'history', routes: routes });
var RouteManage = {
    routes: routes,
    router: router
};

RouteManage.router.beforeEach((to, from, next) => {
    if (to.path.indexOf('/ssohome') >= 0 || to.path.indexOf('/callback') >= 0) {
        next();
        return;
    }
    
    var domainName = Cookie.domain;
    var cookieName = Cookie.loginCookie;
    if (to.path == '/' || to.path == '' || to.path == `/${config.subdomain}/` || to.path == `/${config.subdomain}`) {
        Cookie.remove(domainName);
        Cookie.remove(Cookie.loginCookie);
        next({ path: `/${config.subdomain}/login` });
        return;
    }

    if (to.path.indexOf('/login') >= 0) {
        Cookie.remove(domainName);
        Cookie.remove(Cookie.loginCookie);
        next();
        return;
    }

    if (to.path == `/${config.subdomain}/omsapi/getmanagevalidatecode` || to.path == `/${config.subdomain}/omsapi/getmanagevalidatecode`) {
        NProgress.start();
        next();
        return;
    }

    var domain = getDomain(to.path);
    if (domain != '' && domain != Cookie.get(domainName) && domain!='operations') {
        Cookie.remove(domainName);
        next({ path: `${config.subdomain}/${domain}/login` })
        return;
    }
    if (domain == '' && domain =='operations') {
        Cookie.remove(domainName);
        //next({ path: `${config.subdomain}/login` })
        return;
    }

    NProgress.start();
    next();
    return;
});

RouteManage.router.afterEach(() => {
    NProgress.done();
});
export default {
    data: RouteManage.router,
    routes: RouteManage.routes,
    install: function (Vue, name = "$RouteManage") {
        Vue.use(VueRouter);
        Object.defineProperty(Vue.prototype, name, { value: RouteManage });
    }
}