<template>
    <!-- :isPercentage="true"  -->

    <div class="h-handle-table h-full">
        <slot name="reserve"></slot>
        <div class="h-handle-bg" v-show="!config.isDetailDisplay">
            <slot name="topButtonGroup"></slot>
            <el-collapse-transition>
                
                <quick-search :colDefs="colDefs" :queryParam="queryParam" @onPageChange="onSearch"
                    v-show="showQueryCondation && custShowQueryOptions"></quick-search>
            </el-collapse-transition>
            <div class="filter-condition">
                    <span v-if="countQueryParam() > 0">
                        <i>
                            查询条件：
                        </i>
                        <p v-for="param in queryParam.Params" :key="param.FieldName"
                            v-if="param.hasOwnProperty('FieldName') && param.FieldName != '' && (!param.hasOwnProperty('FieldHidden') || !param.FieldHidden)">
                            <span
                                v-if="param.FieldValue != '' && param.FieldType != '2' && param.FieldType != '3' && param.FieldType != '4'&& param.FieldType != '6' && param.FieldType != '8'"><em>{{
                                    param.FieldLabel }}：</em>{{ param.FieldValue }}<a
                                    v-on:click="delQueryParam(param)">×</a></span>
                            <span
                                v-if="(param.FieldValueStart != '' || param.FieldValueEnd != '') && (param.FieldType == '2' || param.FieldType == '6')"><em>{{
                                    param.FieldLabel }}：</em>{{ param.FieldValueStart | dateFormat }}至{{ param.FieldValueEnd
        | dateFormat }}<a v-on:click="delQueryParam(param)">×</a></span>
                            <span
                                v-if="param.FieldValue != '' && (param.FieldType == '3' || param.FieldType == '4' || param.FieldType == '8')"><em>{{
                                    param.FieldLabel }}：</em>{{ param.FieldValueLabel }}<a
                                    v-on:click="delQueryParam(param)">×</a></span>

                        </p>

                        <el-button class="btn-all-clear" @click.native.prevent="clearQueryParam()" type="text" size="small">
                            全部清除
                        </el-button>
                    </span>
                </div>
        </div>

        <slot name="reserve1"></slot>
        <div class="h-table-list">
            <div class="h-scroll">
                <div  v-show="!config.isDetailDisplay">
                    <slot name="buttonGroup"></slot>
                    <div class="h-table-status" v-if="queryParam.TabShow">
                        <el-row class="h-table-choose">
                            <el-button type="text" v-for="tab in (queryParam.TabItems||[])" :key="tab.TabKey"
                                    @click="showlist(tab.TabKey)"
                                    v-bind:class="queryParam.TabStatus==tab.TabKey? 'activebtn':''">
                                {{ tab.TabText }}({{ tab.TabValue }})
                            </el-button>
                        </el-row>
                    </div>
                </div>
                <field-selector :hidden="configurable == false" :colDefs="colDefs" :dataSource="dataSource"
                    @onDataSourceChange="onDataSourceChange" @onToggleSearch="onToggleSearch"></field-selector>
                <div class="table-s">
                    <slot name="elList"></slot>
                </div>
                <slot name="footer"></slot>
                <el-alert v-if="warningAlert" :title="warningTitle" type="warning" :closable="false"></el-alert>
                <div class="page" v-if="isPage">
                    <el-pagination @size-change="onSizeChange" @current-change="onPageChange"
                        :current-page="queryParam.PageIndex" :page-sizes="sizes" :page-size="queryParam.PageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                    </el-pagination>
                    <el-button type="text" class="btn-refurbish" v-on:click="onRefresh">刷新</el-button>
                </div>
            </div>
        </div>
        <el-dialog title="自定义列表显示条数" :visible.sync="showTotalSetFrm" size="mini" @close="closeDefinePageSizeFrm"
            :modal="false" top="24vh">
            <el-form size="small">
                <el-form-item>
                    <el-col :span="15" :offset="2" class="form-title">
                        <el-input id="inputDefinePageSize" type="number" v-model="AllocNumber"
                            @keyup.enter.native="updatenum"></el-input>
                    </el-col>
                    <el-col :span="5" :offset="1" class="form-title">
                        <el-button type="primary" @click="updatenum">确定</el-button>
                    </el-col>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
import Event from '../../../../scripts/event.js'

export default {
    name: 'fixed-list',
    props: {
        configurable: true, //配置项是否隐藏
        warningAlert: false, //启用警告
        warningTitle: '', //警告信息
        isPercentage: Boolean,
        isPage:{
            type:Boolean,
            default:true
        },
        dataSource: {
            type: Object,
        },
        config: {
            type: Object,
            default: function () {
                return {
                    isDetailDisplay: false,
                };
            }
        },
        PageSizeArray: {
            type: Array,
            default: function () {
                return [10, 20, 30, 50, 100];
            }
        },

        filterList: {
            type: Array,
            default: function () {
                return [];
            }
        },
        // 参数内容
        queryParam: {
            type: Object,
            default: function () {
                return {
                    tabShow: false,
                    TabItems: []
                }
            }
        },
        // 参数内容
        queryParam: {
            type: Object,
            default: function () {
                return {}
            }
        },
        custShowQueryOptions:{
            type:Boolean,
            default:true
        },
    },
    data() {
        return {
            colDefs: {},
            totalCount: 0,
            percentage: this.isPercentage,
            sizes: ['自定义-', 10, 50, 100, 200, 500],
            AllocNumber: 0,
            showTotalSetFrm: false,
            showQueryCondation: true,
        }
    },
    watch: {
        dataSource: {
            handler: function () {
                this.colDefs = this.dataSource.ColDefs;
                this.totalCount = this.dataSource.TotalCount;
            },
            deep: true
        },
    },
    methods: {
        onRefresh() {
            this.$emit('onPageChange', this.queryParam);
        },
        onSizeChange(val) {
            if (isNaN(val)) {
                this.sizes.shift();//移除自定义列
                this.AllocNumber = 30;
                this.showTotalSetFrm = true;
                var _this = this;
                setTimeout(function () {
                    var text = $("#inputDefinePageSize > input:first", _this.$el);
                    text.focus().select();
                }, 100);

            } else {
                this.queryParam.PageSize = val;
                this.$emit('onPageChange', this.queryParam);
            }

        },
        closeDefinePageSizeFrm: function () {
            this.showTotalSetFrm = false;
            if (!isNaN(this.sizes[0])) {
                this.sizes = (['自定义-']).concat(this.sizes);//还原自定义列
            }
        },
        updatenum: function () {
            var num = this.AllocNumber * 1;
            var text = $("#inputDefinePageSize > input:first", this.$el);
            if (!this.Utils.isRegularInt(num)) {
                this.Utils.messageBox("条数必须是正整数", "error");
                text.focus().select();
                return;
            }
            if (num > 1000) {
                this.Utils.messageBox("条数不能大于1000", "error");
                text.focus().select();
                return;
            }
            var arr = ['自定义-'];
            arr = arr.concat(this.sizes.filter(function (item) { return item < num; }));
            arr.push(num);
            arr = arr.concat(this.sizes.filter(function (item) { return item > num; }));
            this.sizes = arr;
            this.queryParam.PageSize = num;
            this.showTotalSetFrm = false;
            this.$emit('onPageChange', this.queryParam);
        },
        onPageChange(val) {
            this.queryParam.PageIndex = val;
            this.$emit('onPageChange', this.queryParam);
        },
        onSearch(val) {
            this.$emit('onPageChange', val);
        },
        onDataSourceChange() {
            this.$emit('onPageChange', this.queryParam);
        },
        onToggleSearch() {
            this.$nextTick(() => {
                this.showQueryCondation = !this.showQueryCondation;
            });
        },
        // onDataSourceChange(val) { this.$emit('onDataSourceChange', val); },
        countQueryParam() {
            var count = 0;
            for (var k in this.queryParam.Params) {
                if (this.queryParam.Params[k]==undefined|| this.queryParam.Params[k].FieldType == null || this.queryParam.Params[k].FieldHidden){
                    continue;
                }
                if (this.queryParam.Params[k].FieldType == 2 || this.queryParam.Params[k].FieldType == 6) {
                    if (this.queryParam.Params[k].FieldName && this.queryParam.Params[k].FieldName != '' && (this.queryParam.Params[k].FieldValueStart != '' || this.queryParam.Params[k].FieldValueEnd != '')) {
                        count++;
                    }
                }
                else
                    if (this.queryParam.Params[k].FieldType == 3 || this.queryParam.Params[k].FieldType == 4 || this.queryParam.Params[k].FieldType == 8) {
                        if (this.queryParam.Params[k].FieldName && this.queryParam.Params[k].FieldName != '' && this.queryParam.Params[k].FieldValue && this.queryParam.Params[k].FieldValue != '') {
                            count++;
                        }
                    }
                    else {
                        if (this.queryParam.Params[k].FieldName && this.queryParam.Params[k].FieldName != '' && this.queryParam.Params[k].FieldValue && this.queryParam.Params[k].FieldValue != '') {
                            count++;
                        }
                    }
            }
            return count;
        },
        delQueryParam(obj) {

            if (this.queryParam.Params[obj.FieldName].FieldType == 3 || this.queryParam.Params[obj.FieldName].FieldType == 4 || this.queryParam.Params[obj.FieldName].FieldType == 8) { this.queryParam.Params[obj.FieldName].FieldValue = []; }
            else {
                this.queryParam.Params[obj.FieldName].FieldValue = "";
            }
            this.queryParam.Params[obj.FieldName].FieldValueStart = "";
            this.queryParam.Params[obj.FieldName].FieldValueEnd = "";

            this.queryParam.Params[obj.FieldName].fFieldValueLabel = "";

            //var newParam = {};
            //for (var k in this.queryParam) {
            //    newParam[k] = this.queryParam[k];
            //}
            /*删除取消的字段,避免ES搜索条件组合错误*/
            for (let key in this.queryParam.Params) {
                if (key !== obj.FieldName) continue;
                delete this.queryParam.Params[key];
            }

            // this.queryParam = newParam;
            this.$emit('onPageChange', this.queryParam);
        },
        clearQueryParam() {
            for (let key in this.queryParam.Params) {
                if (typeof this.queryParam.Params[key] === 'string')
                    continue;
                if (this.queryParam.Params[key].FieldValue) {
                    this.queryParam.Params[key].FieldValue = "";
                }
                if (this.queryParam.Params[key].FieldValueStart) {
                    this.queryParam.Params[key].FieldValueStart = "";
                }
                if (this.queryParam.Params[key].FieldValueEnd) {
                    this.queryParam.Params[key].FieldValueEnd = "";
                }
                if (this.queryParam.Params[key].FieldValueLabel) {
                    this.queryParam.Params[key].FieldValueLabel = "";
                }
            }
            if (!this.queryParam) {
                this.queryParam = {};
            }
            this.queryParam.PageIndex = 0;
            if (!this.queryParam.Params) {
                this.queryParam.Params = {};
            }
            this.$emit('onPageChange', this.queryParam);
        },
        showlist(index) {
            this.queryParam.TabStatus = index;
            this.queryParam.PageIndex = 1;
            this.queryParam.isFilter = null;
            this.$emit('onPageChange', this.queryParam);
        }
    },
    mounted: function () {
        //    alert(this.dataSource.TotalCount)
           this.colDefs = this.dataSource.ColDefs;
           this.totalCount = this.dataSource.TotalCount;
        //$('.h-scroll').scroll(function () {
        //    console.log('ahaha', $(this).parent().find('.table-title').length, $(this).scrollTop());
        //    if ($(this).scrollTop() > 0) {
        //        if ($(this).parent().find('.table-title').length == 0) {
        //            $(this).before('<div class="table-title"></div>');
        //            $(this).parent().find('.table-title').append($(this).find('.el-table__header-wrapper').clone());
        //        }
        //    } else {
        //        $(this).parent().find('.table-title').remove();
        //    }
        //})
    }
}

</script>
<style scoped>
.h-table-status{ float: left;}
.h-table-status .h-table-choose{ background-color:#f1f1f1; border-top:none;}
.h-table-status .h-table-choose::after{ margin-top:0; height:0;}
</style>