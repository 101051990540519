

function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        let str = o[k] + '';
        if (new RegExp(`(${k})`).test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
};

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}
function UTCToLocalDate(value) {
    if (!value) { return '' }
    value = value.toString()
    let date = new Date(value);
    return formatDate(date, 'yyyy-MM-dd');
}
function UTCToLocalDateTime(value) {
    if (!value) { return '' }
    value = value.toString()
    let date = new Date(value); "1-01-01 00:00:00"
    var result = formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    return result == "1-01-01 00:00:00" ? '' : result;
}
export default {
    FormatDate: formatDate,
    UTCToLocalDate: UTCToLocalDate,
    UTCToLocalDateTime: UTCToLocalDateTime,
     install: function (Vue, name = "$DisplayFormat") {
         Vue.filter('dateFormat', function (value) {
             return UTCToLocalDate(value);
         });
         Vue.filter('datetimeFormat', function (value) {
             return UTCToLocalDateTime(value);
         });

    }
}