<template>
    <span>
        <el-button @click.native="onClick">{{label}}</el-button>
        <DialogEx :options="dialogConfig.viewConfig" :title="dataTitle" @onOk="onConfirmOk">
            <fixed-list :dataSource="dataSource" @onDataSourceChange="onDataSourceChange" @onPageChange="initialize" :queryParam="queryParam" :isPage=isPage>
                <el-table ref="list" slot="elList" :data="dataSource.Result" border @selection-change="handleSelectionChange" @current-change="handleCurrentChange" highlight-current-row max-height="340">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column v-for="(col,index)  in (dataSource.ColDefs&&dataSource.ColDefs.BodyFieldParams)"
                        :key="index"
                        :prop="col.FieldName"
                        :label="col.DisplayName"
                        :render-header="bindFilter(queryParam,col)"
                        :fixed="index==0"
                        :min-width="parseInt(col.Width)"
                        v-if="col.Visible">
                    </el-table-column>
                </el-table>
            </fixed-list>
        </DialogEx>
    </span>
</template>
<script>
    export default {
        name: 'GridDialog',
        model: {  },
        data: function () {
            return {
                dialogConfig: {
                    viewConfig:{
                        visible: false,
                        size: 'small'
                    }
                },
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    params: {}
                },
                dataSource: {
                    ColDefs: {
                        BodyFieldParams:[]
                    },
                    Result: [],
                    TotalCount: 0
                },
                selectedRow:{},
                selectedRows: [],
                multipleSelection: [],
            }
        },
        props: {
            currentValue:{
                type: String,
                default: ''
            },
            label:{
                type: String,
                default: '选择'
            },
            dataTitle:{
                type: String,
                default: '数据选择'
            },
            dataMethod:{
                type: String,
                default: 'post'
            },
            dataUrl:{
                type: String,
                default: ''
            },
            syncField:{
                type: String,
                default: ''
            },
            isRequired: { // 是否必填
                type: Boolean,
                default: true
            },
            isPage:{
                type: Boolean,
                default: true
            },
            onChange: { // 变化事件
                type: Function,
                default: null
            },
            readonly: { // 只读
                type: Boolean,
                default: false
            },
            emptyValue: { // 值为空时显示的字符串
                type: String,
                default: '------'
            },
        },
        mounted: function () {
            //this.initialize();
        },
        beforeDestroy() {
            /*页面销毁的时候要保存一些数据，就可以监听这个销毁的生命周期函数*/
        },
        watch: {
        },
        computed: {
        },
        methods: {
            onClick(){
                this.dialogConfig.viewConfig.visible = true;
                this.initialize();
            },
            onShopSelectedRow: function (row) {
                this.currentShopSelectedRow = row;
            },
            onConfirmOk(){
                this.$emit('syncFieldEvent', this.selectedRow,this.multipleSelection);
            },
            handleCurrentChange: function (row) {
                this.selectedRow = row;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            onDataSourceChange(ds) {
                var _this = this;
                // _this.dataSource = {
                //     ColDefs: {
                //         BodyFieldParams: []
                //     },
                //     Result: [],
                //     TotalCount: 0
                // };
                // _this.$nextTick(function () {
                //     _this.dataSource = ds;
                // });
            },
            initialize() { 
                let _this = this;
                if (_this.selectCondition) {
                    _this.Utils.extend(_this.queryParam.params, _this.selectCondition);
                }
                 var dataUrl= this.dataUrl;
                 var method = this.dataMethod;
                _this.$ajax.send(dataUrl, method, _this.queryParam, (data) => {
                    if(data.IsSuccess){
                        _this.dataSource = data;
                    }
                }, null, false);
            }
        }
    }
</script>