<template>
    <div></div>
</template>
<script>
    import Event from '../../../../scripts/event.js'
    import textfilter from './fieldfiltertext.vue'
export default {
    name: 'field-filter',
        bindFilter: function (context, col, ignoreField = undefined) {
            if ((ignoreField != undefined && ignoreField.indexOf(col.FieldName) >= 0) || (!col.Sortable && !col.Filterable))
    {
      return function (h, { column }) {
              return h(
                'p',
                {

                },
                [
                    h('span', { }, [column.label,
                    h('s')
                    ])
                ]
            )
        }
    }

        return function (h, { column }) {
              return h(
                'p',
                {
                    on: {
                        click: (e) => {
                            var eventname = "";
                          
                            if (col.FieldType == "2")
                            {
                                eventname = "datefilter";
                            }
                            else  if (col.FieldType == "6")
                            {
                                eventname = "datetimefilter";
                            }
                            else if (col.FieldType == "5")
                            {
                                eventname = "numberfilter";
                            }
                            else if (col.FieldType == "3" || col.FieldType == "4" || col.FieldType == "8")
                            {
                                eventname = "enumfilter";
                            }
                            else {
                                eventname = "textfilter";

                            }
                            Event.$emit(eventname, { e: e, column: column, context: context, colDef: col, parent: this.$parent.$parent })
                            //Event.$emit(eventname, { e: e, column: column, context: context,colDef:col })

                        }
                    }
                },
                [
                    h('span', { class: 'arrow' }, [column.label,
                    h('s')
                    ])
                ]
            )
        }

    }
}
</script>
<style>

</style>