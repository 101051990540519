import Vue from 'vue'
import App from './App.vue'
import './assets/css/style.css';
import Utils from './scripts/utils.js';
import RouteManage from './scripts/routemanage';
import AjaxRequest from './scripts/ajaxrequest.js';
import DisplayFormat from './scripts/displayformat.js';
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui'
import DynamicList from './components/common/dynamiclist'
import FixedList from './components/common/fixedlist'
import FieldSelector from './components/common/fieldselector'
import FieldFilter from './components/common/fieldfilter'
import QuickSearch from './components/common/quicksearch'
import vClickOutside from 'v-click-outside'
import Store from './scripts/store.js';
import crypto from './scripts/crypto.js';
Vue.use(crypto);
import ChooseCustomer from './components/common/choosecustomer'
import LookPopup from './components/common/base/lookPopup'
import DictionarySelect from './components/common/base/dic';
import GridDialog from './components/common/base/gridDialog';
import wl_select from './components/common/base/wl_select';
import wl_look_popup from './components/common/base/wl_look_popup';

import InlineTable from './components/common/inlinetable';
Vue.use(InlineTable);

import DialogEx from './components/common/dialogex';
import DialogCtn from './components/common/dialogctn';
import DialogExt from './components/common/dialogext';
import Distpicker from './components/common/distpicker';
import DynamicCotrol from './components/common/dynamiccotrol';
import DistTree from './components/common/disttree';
import VueDND from 'awe-dnd'


Vue.use(VueDND)
Vue.use(DictionarySelect)

Vue.use(DialogEx);
Vue.use(DialogCtn);
Vue.use(DialogExt);
Vue.use(Distpicker);
Vue.use(DistTree);
Vue.use(DynamicCotrol);

Vue.use(vClickOutside);
Vue.use(FieldFilter);
Vue.use(RouteManage);
Vue.use(ElementUI);
    
Vue.use(AjaxRequest);
Vue.use(Utils);
Vue.use(DisplayFormat);
Vue.use(DynamicList);
Vue.use(FixedList);
Vue.use(FieldSelector);
Vue.use(QuickSearch);
Vue.use(ChooseCustomer);
Vue.use(LookPopup);
Vue.use(GridDialog);
Vue.use(wl_select);
Vue.use(wl_look_popup);

Vue.config.productionTip = false
Vue.prototype.AppConfig = window.AppConfig

new Vue({
  store: Store, 
  router: RouteManage.data,
  render: h => h(App),
}).$mount('#app')
